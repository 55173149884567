import { useCallback, useEffect } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { views, validationMode } from "../utils/constants";

import { useHistory, useParams } from "react-router-dom";
import { useIsMobile, useSnackbar } from "../utils/hooks";

import clsx from "clsx";
import SecureEnv from "../components/SecureEnv";
import { FullscreenLayout } from "../components/Layouts";
import { UilArrowRight } from "@iconscout/react-unicons";
import MobileHomeLeft from "../components/MobileHomeLeft";
import DesktopHomeLeft from "../components/DesktopHomeLeft";
import { Grid, Typography, makeStyles } from "@material-ui/core";

import { useSelector, useDispatch } from "react-redux";

import {
  resetStorage,
  selectIsLoading,
  storeCode,
  storeView,
  saveCpf,
} from "../store/card_reducer";

import { CpfField, homeSchema } from "../components/ReadyComponents";
import {
  FormBackdrop,
  FormButtonContained,
  Separator,
} from "../components/RawComponents";

const Home = ({ view = views.default }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const { codigo } = useParams();
  const isLoading = useSelector(selectIsLoading);

  const { showErrorDefaultSnackbar } = useSnackbar();

  // Reset session storage
  useEffect(() => {
    dispatch(resetStorage());
  }, [dispatch]);

  // CONSULTOR VIEW: Store consultor code
  useEffect(() => {
    dispatch(storeView(view));

    if (view === views.consultor || view === views.cliente) {
      dispatch(storeCode(codigo));
      dispatch(storeView(view));
    }
  }, [dispatch, codigo, view]);

  const { control, errors, handleSubmit, setError } = useForm({
    defaultValues: {
      [CpfField.fieldName]: "",
    },
    mode: validationMode,
    resolver: yupResolver(homeSchema),
  });

  const onSubmit = useCallback(
    async (formData) => {
      try {
        const result = await dispatch(saveCpf(formData));

        if (saveCpf.fulfilled.match(result)) {
          history.push("/cartao-inss-identificacao");
        } else {
          showErrorDefaultSnackbar(result);
        }
      } catch (err) {
        setError("cpf", "Erro no cpf");
      }
    },
    [history, setError, dispatch, showErrorDefaultSnackbar]
  );

  const classes = useStyles();

  return (
    <FullscreenLayout className={classes.root}>
      {isMobile && <MobileHomeLeft />}

      {!isMobile && <DesktopHomeLeft />}

      <div className={classes.formContainer}>
        <Grid
          container
          spacing={6}
          classes={{ root: classes.form }}
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate={true}
        >
          {!isMobile && (
            <Grid item xs={12} style={{ marginBottom: "16px" }}>
              <Typography className={classes.title}>
                Cartão de Crédito
              </Typography>
              <Typography className={clsx(classes.title, classes.pink)}>
                Consignado
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <CpfField text="Insira seu CPF" control={control} errors={errors} />
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              marginTop: isMobile ? "0px" : "8px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FormButtonContained type="submit">
              Começar
              <UilArrowRight
                style={{
                  marginLeft: "8px",
                  width: "32px",
                  height: "32px",
                }}
              />
            </FormButtonContained>
            <Separator spacing={2} />
            {isMobile && <SecureEnv inForm />}
          </Grid>

          {view === views.consultor && (
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography className={classes.codigoConsultor}>
                Código do consultor: {codigo}
              </Typography>
            </Grid>
          )}
        </Grid>
      </div>
      <FormBackdrop isLoading={isLoading} />
    </FullscreenLayout>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: "column",
  },
  formContainer: {
    width: "100%",
    height: "40%",
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
    paddingTop: "24px",
    padding: "24px 12px 12px 12px",
    overflow: "auto",
  },
  scrollableFormContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
    height: "100%",
    width: "100%",
  },
  form: {
    backgroundColor: "#fff",
    width: "100%",
    maxWidth: "450px",
    minHeight: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    color: theme.palette.secondary.main,
    textAlign: "center",
    fontSize: "32px",
    fontWeight: theme.typography.fontWeightBold,
    fontFamily: theme.typography.secondaryFontFamily,
    lineHeight: "41.6px",
  },
  pink: {
    color: "#F23A7D",
  },
  codigoConsultor: {
    fontWeight: theme.typography.fontWeightSemibold,
    color: theme.palette.text.primary,
    fontFamily: theme.typography.primaryFontFamily,
  },
  [theme.breakpoints.up("md")]: {
    root: {
      flexDirection: "row",
    },
    formContainer: {
      paddingTop: "38px",
      width: "50%",
      height: "100%",
      overflow: "auto",
      alignItems: "center",
    },
    form: {
      maxWidth: "400px",
      width: "100%",
      minWidth: "328px",
    },
    formTitle: {
      fontSize: "30px",
    },
  },
}));

export default Home;
