import DadosPessoais from "../pages/steps/DadosPessoais";
import Endereco from "../pages/steps/Endereco";

import { UilUser, UilMapMarker, UilFileCheck } from "@iconscout/react-unicons";
import Identificacao from "../pages/steps/Identificacao";

const identificacaoStep = {
  url: "/cartao-inss-identificacao",
  title: "Identificação",
  Component: Identificacao,
  Icon: UilFileCheck,
  entity: "identificacao",
};

const dadosPessoaisStep = {
  url: "/cartao-inss-dados-pessoais",
  title: "Dados Pessoais",
  Component: DadosPessoais,
  Icon: UilUser,
  entity: "dados-pessoais",
};

const enderecoStep = {
  url: "/cartao-inss-endereco",
  title: "Endereço Residencial",
  Component: Endereco,
  Icon: UilMapMarker,
  entity: "endereco",
};

export const noAccountPath = {
  "cartao-inss-identificacao": {
    ...identificacaoStep,
    back: "/",
    next: "cartao-inss-dados-pessoais",
    num: 1,
  },
  "cartao-inss-dados-pessoais": {
    ...dadosPessoaisStep,
    back: "/cartao-inss-identificacao",
    next: "cartao-inss-endereco",
    nextTitle: "Próximo: Endereço Residencial",
    num: 2,
  },
  "cartao-inss-endereco": {
    ...enderecoStep,
    back: "cartao-inss-dados-pessoais",
    next: "/cartao-inss-feedback",
    nextTitle: "",
    num: 3,
    isLast: true,
  },
};

// Images
export const images = {
  homeMobile: "/images/home_mobile.png",
  homeDesktop: "/images/home_desktop.png",
  feedbackMobile: "/images/feedback_mobile.png",
  feedbackDesktop: "/images/feedback_desktop.png",
  successCheck: "/images/success_check.gif",
};

export function toBool(str) {
  return str === "true" ? true : false;
}

// Env Options
export const isServerConnected = toBool(process.env.REACT_APP_SERVER_CONNECTED);

// Form
export const validationMode = "onBlur";

// Views
const defaultView = "default_view";
const clienteView = "cliente_view";
const consultorView = "consultor_view";

export const views = {
  default: defaultView,
  cliente: clienteView,
  consultor: consultorView,
};
