import Header from "../components/Header";

import { useSelector } from "react-redux";

import { noAccountPath, views } from "../utils/constants";
import { Redirect, useHistory, useParams } from "react-router-dom";

import { useIsMobile, useTransition } from "../utils/hooks";

import { Grow } from "@material-ui/core";
import { FormBackdrop } from "../components/RawComponents";
import { ConsultorFooter } from "../components/ConsultorFooter";
import { FullscreenLayout, ManagerLayout } from "../components/Layouts";

import {
  selectCodigoConsultor,
  selectIsLoading,
  selectView,
} from "../store/card_reducer";

const getContent = (step, handleNext, handleBack) => {
  const stepProperties = noAccountPath[step];

  if (!stepProperties) {
    return <Redirect to={"/"} />;
  }

  return (
    <stepProperties.Component handleNext={handleNext} handleBack={handleBack} />
  );
};

const StepManager = () => {
  const path = noAccountPath;

  const { step } = useParams();
  const history = useHistory();
  const isLoading = useSelector(selectIsLoading);

  const transition = useTransition(true, 300);
  const isMobile = useIsMobile();

  const view = useSelector(selectView);
  const code = useSelector(selectCodigoConsultor);

  const handleNext = () => {
    transition.run().then(() => {
      if (view === views.consultor && path[step].isLast) {
        history.push(path[step]?.next + "consultor/cartao-inss-" + code);
      } else if (view === views.cliente && path[step].isLast) {
        history.push(path[step]?.next + "cliente/cartao-inss-" + code);
      } else {
        history.push(path[step]?.next);
      }

      window.scroll(0, 0);
    });
  };

  const handleBack = () => {
    transition.run().then(() => {
      if (view === views.consultor && path[step]?.num === 1) {
        history.push(path[step]?.back + "consultor/cartao-inss-" + code);
      } else if (view === views.cliente && path[step]?.num === 1) {
        history.push(path[step]?.back + "cliente/cartao-inss-" + code);
      } else {
        history.push(path[step]?.back);
      }
      window.scroll(0, 0);
    });
  };

  return (
    <FullscreenLayout>
      {isMobile && (
        <Header step={path[step]?.num} stepTitle={path[step]?.title} />
      )}

      <ManagerLayout path={path} currentStep={step}>
        <Grow
          mountOnEnter
          unmountOnExit
          in={transition.in}
          timeout={transition.duration}
        >
          <div>{getContent(step, handleNext, handleBack)}</div>
        </Grow>
      </ManagerLayout>

      <FormBackdrop isLoading={isLoading} />

      {view === views.consultor && <ConsultorFooter />}
    </FullscreenLayout>
  );
};

export default StepManager;
