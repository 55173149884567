import { makeStyles } from "@material-ui/core";
import { ReactComponent as EuqueerogranaLogo } from "../images/eu_quero_grana.svg";

const FeedbackHeader = ({ step, stepTitle, style }) => {
  const classes = useStyles();

  return (
    <div className={classes.root} style={style}>
      <div className={classes.container}>
        <EuqueerogranaLogo className={classes.eqgLogo} />
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    display: "flex",
    background:
      "linear-gradient(90deg, #eb6c00, #eb6d00, #ec7100, #ee7701, #ef7f02, #f18605, #f38e0a, #f49610, #f59d15, #f6a219, #f7a61c, #f7a71d)",
    height: "74px",
    justifyContent: "center",
    padding: "24px",
    width: "100%",
  },
  container: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center ",
    maxWidth: "454px",
    width: "100%",
  },
  eqgLogo: {
    transform: "scale(1.2)",
  },
}));

export default FeedbackHeader;
