import { makeStyles, Typography } from "@material-ui/core";

import { ReactComponent as DesktopLogo } from "../images/desktop_home_logo.svg";
import { images } from "../utils/constants";
import SecureEnv from "./SecureEnv";

const DesktopHomeLeft = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <DesktopLogo />
        </div>
        <div className={classes.imgContainer}>
          <img src={images.homeDesktop} alt="home" className={classes.img} />
        </div>

        <div className={classes.textContainer}>
          <Typography className={classes.title}>
            A melhor idade tem o privilégio da
          </Typography>
          <Typography className={classes.title}>
            melhor solução financeira.
          </Typography>
        </div>

        <SecureEnv whiteMode style={{ marginTop: "34px" }} />
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    background:
      "linear-gradient(180deg, #eb6c00, #eb6d00, #ec7100, #ee7701, #ef7f02, #f18605, #f38e0a, #f49610, #f59d15, #f6a219, #f7a61c, #f7a71d)",
    height: "100%",
    width: "50%",
    overflow: "hidden",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "flex-end",
    padding: "36px 48px 36px 110px",
    height: "100%",
    maxHeight: "900px",
    width: "100%",
  },
  imgContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
    width: "100%",
    marginBottom: "52px",
    marginTop: "54px",
    flex: "1 1 auto",
  },
  img: {
    minWidth: "150px",
    width: "60vh",
  },
  eqgLogo: {
    marginTop: "16px",
    transform: "scale(2)",
  },
  melhorIdadeLogo: {
    marginTop: "16px",
    transform: "scale(1.5)",
  },
  melhorIdadeContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
    width: "100%",
    marginTop: "40px",
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "center",
    width: "100%",
  },
  title: {
    color: theme.palette.text.secondary,
    fontFamily: theme.typography.secondaryFontFamily,
    fontWeight: theme.typography.fontWeightBold,
    textShadow: "0px 2px 4px #35240E1A",
    fontSize: "24px",
    textAlign: "center",
  },
  titleHighlight: {
    fontWeight: theme.typography.fontWeightExtrabold,
  },
  stepperContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginTop: "3vh",
  },
  "@media (max-height: 810px)": {
    eqgLogo: {
      transform: "scale(1.5)",
      marginRight: "-8px",
    },
    img: {
      width: "45vh",
    },
  },
  "@media (max-height: 670px)": {
    root: {
      overflow: "auto",
    },
    eqgLogo: {
      marginRight: "4px",
      transform: "scale(1.3)",
    },
    img: {
      width: "45vh",
    },
  },
  [theme.breakpoints.only("md")]: {
    container: {
      padding: "36px 48px 36px 48px",
    },
    title: {
      fontSize: "24px",
      margin: "0px -35px",
    },
  },
}));

export default DesktopHomeLeft;
