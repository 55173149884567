import { makeStyles, Typography } from "@material-ui/core";

import { useSelector } from "react-redux";
import { selectCodigoConsultor } from "../store/card_reducer";
import { useIsMobile } from "../utils/hooks";

export const ConsultorFooter = (props) => {
  const isMobile = useIsMobile();
  const classes = useStyles();
  const code = useSelector(selectCodigoConsultor);
  return (
    <div className={classes.root} {...props}>
      <div className={classes.contentContainer}>
        {!isMobile && (
          <div className={classes.titleContainer}>
            <Typography className={classes.title}>Consultor</Typography>
          </div>
        )}
        <div className={classes.detailsContainer}>
          <Typography className={classes.codigo}>
            Código <span className={classes.codigoSpan}>{code}</span>
          </Typography>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    padding: "0px 0px 24px 0px",
  },
  contentContainer: {
    backgroundColor: theme.palette.secondary.main,
    width: "90vw",
    height: "30px",
    boxShadow: theme.shadows[1],
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    marginLeft: 12,
    marginRight: 12,
    padding: "24px",
    maxWidth: "468px",
    borderRadius: "24px",
  },
  titleContainer: {
    width: "30%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderLeft: "10px",
    borderLeftColor: "grey",
  },
  title: {
    fontFamily: theme.typography.secondaryFontFamily,
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightBold,
    textAlign: "center",
  },
  detailsContainer: {
    width: "70%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  codigo: {
    fontFamily: theme.typography.secondaryFontFamily,
    fontSize: "16px",
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightSemibold,
  },
  codigoSpan: {
    fontWeight: theme.typography.fontWeightBold,
  },
  [theme.breakpoints.down("xs")]: {
    root: {
      flexDirection: "column",
      height: "40px",
    },
    titleContainer: {
      width: "100%",
    },
    detailsContainer: {
      width: "100%",
    },
  },
}));
