import { useCallback, useState, useRef } from "react";

import { useMediaQuery } from "@material-ui/core";
import { useSnackbar as useNotiSnackbar } from "notistack";

export const useIsMobile = () => {
  return useMediaQuery((theme) => theme.breakpoints.down("sm"));
};

export const useSnackbar = () => {
  const snackbar = useNotiSnackbar();

  const showSnackbar = useCallback(
    (message, type) => {
      snackbar.enqueueSnackbar(message, {
        variant: type,
        anchorOrigin: { horizontal: "center", vertical: "top" },
      });
    },
    [snackbar]
  );

  const showErrorDefaultSnackbar = useCallback(
    (result) => {
      snackbar.enqueueSnackbar(
        process.env.NODE_ENV !== "development"
          ? "Erro no servidor"
          : `Status: ${result?.payload?.response?.status}
      Message: ${result?.payload?.message}`,
        {
          variant: "error",
          anchorOrigin: { horizontal: "center", vertical: "top" },
        }
      );
    },
    [snackbar]
  );

  return { ...snackbar, showSnackbar, showErrorDefaultSnackbar };
};

export function useTransition(initialState, duration) {
  const [isIn, setIsIn] = useState(initialState);

  function run() {
    setIsIn(false);

    return new Promise((resolve) =>
      setTimeout(() => {
        setIsIn(true);
        resolve();
      }, duration)
    );
  }

  return { duration, run, in: isIn };
}

const safeDocument = typeof document !== "undefined" ? document : {};

/**
 * Usage:
 * const [blockScroll, allowScroll] = useScrollBlock();
 */
export const useScroll = () => {
  const scrollBlocked = useRef();
  const html = safeDocument.documentElement;
  const { body } = safeDocument;

  const blockScroll = () => {
    if (!body || !body.style || scrollBlocked.current) return;

    const scrollBarWidth = window.innerWidth - html.clientWidth;
    const bodyPaddingRight =
      parseInt(
        window.getComputedStyle(body).getPropertyValue("padding-right")
      ) || 0;

    /**
     * 1. Fixes a bug in iOS and desktop Safari whereby setting
     *    `overflow: hidden` on the html/body does not prevent scrolling.
     * 2. Fixes a bug in desktop Safari where `overflowY` does not prevent
     *    scroll if an `overflow-x` style is also applied to the body.
     */
    html.style.position = "relative"; /* [1] */
    html.style.overflow = "hidden"; /* [2] */
    body.style.position = "relative"; /* [1] */
    body.style.overflow = "hidden"; /* [2] */
    body.style.paddingRight = `${bodyPaddingRight + scrollBarWidth}px`;

    scrollBlocked.current = true;
  };

  const allowScroll = () => {
    if (!body || !body.style || !scrollBlocked.current) return;

    html.style.position = "";
    html.style.overflow = "";
    body.style.position = "";
    body.style.overflow = "";
    body.style.paddingRight = "";

    scrollBlocked.current = false;
  };

  return [blockScroll, allowScroll];
};
