import clsx from "clsx";

import { ReactComponent as EuqueerogranaLogo } from "../images/eu_quero_grana.svg";
import { ReactComponent as MelhoridadeLogo } from "../images/melhor_idade_logo.svg";
import { Box, Container, Grid, makeStyles } from "@material-ui/core";
import { VerticalStep, VerticalStepper } from "./VerticalStepper";
import SecureEnv from "./SecureEnv";
import { useIsMobile } from "../utils/hooks";

export const FullscreenLayout = ({ children, className, ...rest }) => {
  const classes = useStylesFullscreenLayout();

  return (
    <Container
      disableGutters
      maxWidth={false}
      className={clsx(classes.container, className)}
      {...rest}
    >
      {children}
    </Container>
  );
};

const useStylesFullscreenLayout = makeStyles((theme) => ({
  container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    minHeight: "100vh",
    height: "100vh",
    width: "100%",
  },
}));

export const FormLayout = ({
  children,
  gridProps,
  onSubmit,
  spacing = 7,
  ...rest
}) => {
  const isMobile = useIsMobile();

  return (
    <Box
      alignItems="center"
      component="form"
      display="flex"
      flexDirection="column"
      justifyContent="start"
      onSubmit={onSubmit}
      padding={`${isMobile ? 24 : 36}px 24px 24px 24px`}
      width="100%"
      maxWidth="504px"
      noValidate={true}
      {...rest}
    >
      <Grid container spacing={spacing} {...gridProps}>
        {children}
      </Grid>
    </Box>
  );
};

export const ManagerLayout = ({ children, path, currentStep }) => {
  const classes = useStylesManagerLayout();

  return (
    <div className={classes.root}>
      <div className={classes.stepperPanel}>
        <div className={classes.logoContainer}>
          <EuqueerogranaLogo className={classes.logo} />
        </div>
        <div className={classes.stepperContainer}>
          <VerticalStepper activeStep={path[currentStep]?.num}>
            {Object.keys(path).map((step) => (
              <VerticalStep
                key={path[step].title}
                Icon={path[step].Icon}
                title={path[step].title}
                subtitle={`Passo ${path[step].num}`}
              />
            ))}
          </VerticalStepper>
        </div>
        <div className={classes.imageContainer}>
          <MelhoridadeLogo className={classes.melhoridadelogo} />
          <SecureEnv whiteMode />
        </div>
      </div>
      <div className={classes.contentPanel}>{children}</div>
    </div>
  );
};

const useStylesManagerLayout = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "start",
    alignItems: "start",
    height: "100vh",
    width: "100%",
  },
  stepperPanel: {
    background:
      "linear-gradient(90deg, #cf3766, #d13865, #d73b62, #de405d, #e74856, #ee514e, #f55d45, #fa693b, #fc7432, #fe7d29, #fe8422, #fe861f)",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "center",
    paddingLeft: "130px",
    paddingRight: "64px",
    minWidth: "500px",
    overflow: "auto",
  },
  logoContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "start",
    marginTop: "48px",
  },
  logo: {
    position: "relative",
    left: "-80px",
    transform: "scale(2)",
  },
  stepperContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10vh",
  },
  imageContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    marginBottom: "32px",
    flex: "1 1 auto",
  },
  melhoridadelogo: {
    marginBottom: "32px",
    transform: "scale(0.65)",
  },
  contentPanel: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
  },
  image: {
    height: "30vh",
  },
  secureEnv: {
    width: "100%",
    paddingBottom: "32px",
  },
  [theme.breakpoints.down("sm")]: {
    root: {
      overflow: "visible",
    },
    stepperPanel: {
      display: "none",
    },
    contentPanel: {
      width: "100%",
      overflow: "visible",
    },
  },
}));
