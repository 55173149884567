import { makeStyles, Typography } from "@material-ui/core";
import { ReactComponent as LockAlt } from "../images/lock-alt.svg";

import clsx from "clsx";

const SecureEnv = ({ whiteMode, style, inForm, ...rest }) => {
  const classes = useStyles();

  return (
    <div
      className={classes.root}
      style={{ marginBottom: inForm ? "24px" : "0px", ...style }}
      {...rest}
    >
      <LockAlt
        className={clsx(classes.lockIcon, { [classes.whiteMode]: whiteMode })}
      />
      <Typography
        className={clsx(classes.subTitle, { [classes.whiteMode]: whiteMode })}
      >
        Você está em um ambiente seguro.
      </Typography>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  lockIcon: {
    color: "#fff",
    width: "20px",
    marginRight: theme.spacing(2),
  },
  subTitle: {
    color: "#555555",
    fontFamily: theme.typography.primaryFontFamily,
    fontSize: "14px",
  },
  whiteMode: {
    color: "white",
    fontSize: "16px",
    "& path": {
      fill: "white",
    },
  },
}));

export default SecureEnv;
