import React, { useMemo } from "react";

import * as yup from "yup";
import { useWatch } from "react-hook-form";

import { useSelector } from "react-redux";
import { selectIdentificacao } from "../store/card_reducer";

import { useIsMobile } from "../utils/hooks";

import { states } from "@unicabr/front-utils";

import SecureEnv from "./SecureEnv";
import { Typography, Grid } from "@material-ui/core";

import {
  isOlderThan18,
  validateBirthday,
  validateNameCharacters,
  validateCpf,
  validateCompleteName,
  validateEmail,
  validatePhone,
  getCitiesFromState,
} from "@unicabr/front-utils";

import {
  ControlledCurrencyField,
  ControlledMaskedField,
  ControlledRatingField,
  FormAutocomplete,
  FormButtonSet,
  FormCheckbox,
  FormRadioControl,
  FormRadioGroup,
  FormTextField,
  LabelArea,
} from "./RawComponents";

export const FormFooter = ({ onClickNext, onClickBack, nextText, type }) => {
  const isMobile = useIsMobile();

  return (
    <>
      <Grid item xs={12} style={{ paddingTop: "28px" }}>
        <FormButtonSet
          onClickNext={onClickNext}
          onClickBack={onClickBack}
          nextText={nextText}
          type={type}
        />
      </Grid>

      <Grid item xs={12} style={{ marginTop: "-8px" }}>
        {isMobile && <SecureEnv />}
      </Grid>
    </>
  );
};

export const CpfField = ({ text, control, errors, ...rest }) => {
  return (
    <LabelArea text={text || "CPF"}>
      <ControlledMaskedField
        control={control}
        isNumeric
        errorMessage={errors?.cpf?.message}
        mask="999.999.999-99"
        placeholder="000.000.000-00"
        name="cpf"
        {...rest}
      />
    </LabelArea>
  );
};

CpfField.fieldName = "cpf";
CpfField.schema = yup
  .string()
  .required("Insira o CPF")
  .test("cpf_test", "CPF inválido", (cpf) => validateCpf(cpf));

export const NomeField = ({ register, errors }) => {
  return (
    <LabelArea text="Nome completo" hint="(sem abreviações)">
      <FormTextField
        inputRef={register}
        name="nomeCompleto"
        errorMessage={errors.nomeCompleto?.message}
      />
    </LabelArea>
  );
};

NomeField.fieldName = "nomeCompleto";
NomeField.schema = yup
  .string()
  .required("Insira o nome completo")
  .max(255, "Precisa ter até 255 caracteres")
  .test("character_test", "Precisa ter apenas letras", (nome) =>
    validateNameCharacters(nome)
  )
  .test("nome_test", "Insira o nome completo", (nome) =>
    validateCompleteName(nome)
  );

export const DataNascimentoField = ({ control, errors }) => {
  return (
    <LabelArea text="Data de nascimento">
      <ControlledMaskedField
        control={control}
        name="dataNascimento"
        type="tel"
        mask="99/99/9999"
        isNumeric
        placeholder="00/00/0000"
        errorMessage={errors.dataNascimento?.message}
      />
    </LabelArea>
  );
};

DataNascimentoField.fieldName = "dataNascimento";
DataNascimentoField.schema = yup
  .string()
  .required("Insira a data de nascimento")
  .test("older_than_18", "Você deve ser maior de 18 anos", (birthday) =>
    isOlderThan18(birthday)
  )
  .test("birthday_test", "Data de nascimento inválida", (birthday) =>
    validateBirthday(birthday)
  );

export const NumCelularField = ({ control, errors }) => {
  return (
    <LabelArea text="Número de celular" hint="(com DDD)">
      <ControlledMaskedField
        control={control}
        mask="(99) 99999-9999"
        placeholder="(00) 00000-0000"
        name="numCelular"
        isNumeric
        errorMessage={errors.numCelular?.message}
      />
    </LabelArea>
  );
};

NumCelularField.fieldName = "numCelular";
NumCelularField.schema = yup
  .string()
  .required("Insira o número de celular")
  .test("celular_test", "Número de celular inválido", (phoneNum) =>
    validatePhone(phoneNum)
  );

export const EstadoNascimentoField = ({ control, errors, customOnChange }) => {
  return (
    <LabelArea text={"Estado de nascimento"}>
      <FormAutocomplete
        control={control}
        name="estNascimento"
        customOnChange={customOnChange}
        errorMessage={errors.estNascimento?.message}
        renderOption={(option) => <Typography>{option.label}</Typography>}
        getOptionLabel={(option) => option.label}
        getOptionSelected={(option, value) => option.value === value.value}
        options={states}
      />
    </LabelArea>
  );
};

EstadoNascimentoField.fieldName = "estNascimento";
EstadoNascimentoField.schema = yup
  .object()
  .nullable()
  .required("Selecione o estado de nascimento");

export const CidadeNascimentoField = ({ control, errors, estado }) => {
  const cities = useMemo(() => getCitiesFromState(estado), [estado]);

  return (
    <LabelArea text={"Cidade de nascimento"}>
      <FormAutocomplete
        control={control}
        errorMessage={errors.cidNascimento?.message}
        name="cidNascimento"
        options={cities}
      />
    </LabelArea>
  );
};

CidadeNascimentoField.fieldName = "cidNascimento";
CidadeNascimentoField.schema = yup
  .string()
  .nullable()
  .required("Selecione a cidade de nascimento");

export const SexoField = ({ control, errors, selectedSexo }) => {
  return (
    <LabelArea text={"Sexo"}>
      <FormRadioGroup
        control={control}
        name="sexo"
        helperText={errors.sexo?.message}
      >
        <FormRadioControl
          value="M"
          label="Masculino"
          checked={selectedSexo === "M"}
          error={errors.sexo?.message}
        />
        <FormRadioControl
          value="F"
          checked={selectedSexo === "F"}
          label="Feminino"
          error={errors.sexo?.message}
        />
        <FormRadioControl
          value="I"
          label="Prefiro não dizer"
          checked={selectedSexo === "I"}
          error={errors.sexo?.message}
        />
      </FormRadioGroup>
    </LabelArea>
  );
};

SexoField.fieldName = "sexo";
SexoField.schema = yup.string().required("Selecione uma opção");

export const InssField = ({ control, errors }) => {
  const inssDefault =
    useSelector(selectIdentificacao)?.[InssField.fieldName] || "";
  const selectedInss = useWatch({
    control: control,
    defaultValue: inssDefault,
    name: InssField.fieldName,
  });

  return (
    <LabelArea text="É Aposentado/Pensionista INSS?">
      <FormRadioGroup
        control={control}
        name={InssField.fieldName}
        helperText={errors[InssField.fieldName]?.message}
      >
        <FormRadioControl
          value="true"
          label="Sim"
          checked={selectedInss === "true"}
          error={errors[InssField.fieldName]?.message}
        />
        <FormRadioControl
          value="false"
          label="Não"
          checked={selectedInss === "false"}
          error={errors[InssField.fieldName]?.message}
        />
      </FormRadioGroup>
    </LabelArea>
  );
};

InssField.fieldName = "inss";
InssField.schema = yup.string().required("Selecione uma opção");

export const CommentField = ({ register, errors }) => {
  return (
    <LabelArea text="Descreva sua experiência" hint="(opcional)">
      <FormTextField
        multiline
        rows={5}
        height="auto"
        name={CommentField.fieldName}
        inputRef={register}
        errorMessage={errors[CommentField.fieldName]?.message}
        placeholder="Escreva sua mensagem aqui..."
      />
    </LabelArea>
  );
};

CommentField.fieldName = "comment";
CommentField.schema = yup.string();

export const CepField = ({
  control,
  errors,
  handleOnBlurCep,
  isCepLoading,
}) => {
  return (
    <LabelArea text="CEP">
      <ControlledMaskedField
        control={control}
        name="cep"
        placeholder="00000-000"
        isNumeric
        disabled={isCepLoading}
        isLoading={isCepLoading}
        customOnBlur={handleOnBlurCep}
        errorMessage={errors.cep?.message}
        mask={"99999-999"}
      />
    </LabelArea>
  );
};

CepField.fieldName = "cep";
CepField.schema = yup
  .string()
  .required("Insira o CEP")
  .length(9, "Precisa ter 8 dígitos");

export const LogradouroField = ({ register, errors, isCepLoading }) => {
  return (
    <LabelArea text="Endereço">
      <FormTextField
        placeholder="Rua, Avenida etc."
        inputRef={register}
        errorMessage={errors.logradouro?.message}
        disabled={isCepLoading}
        name="logradouro"
      />
    </LabelArea>
  );
};

LogradouroField.fieldName = "logradouro";
LogradouroField.schema = yup
  .string()
  .required("Insira o endereço")
  .max(255, "Precisa ter até 255 caracteres");

export const NumeroField = ({ control, errors }) => {
  return (
    <LabelArea text="Número">
      <ControlledMaskedField
        control={control}
        placeholder="0000"
        name="numero"
        mask="9999999999"
        isNumeric
        errorMessage={errors.numero?.message}
      />
    </LabelArea>
  );
};

NumeroField.fieldName = "numero";
NumeroField.schema = yup.string().required("Insira o número");

export const ComplementoField = ({ register, errors }) => {
  return (
    <LabelArea text="Complemento" hint="(opcional)">
      <FormTextField
        placeholder="Apartamento, Bloco etc."
        name="complemento"
        inputRef={register}
        errorMessage={errors.complemento?.message}
      />
    </LabelArea>
  );
};

ComplementoField.fieldName = "complemento";
ComplementoField.schema = yup
  .string()
  .max(255, "Precisa ter 255 caracteres ou menos");

export const BairroField = ({ register, errors, isCepLoading }) => {
  return (
    <LabelArea text="Bairro">
      <FormTextField
        inputRef={register}
        name="bairro"
        disabled={isCepLoading}
        errorMessage={errors.bairro?.message}
      />
    </LabelArea>
  );
};

BairroField.fieldName = "bairro";
BairroField.schema = yup
  .string()
  .required("Insira o bairro")
  .max(255, "Precisa ter até 255 caracteres");

export const EstadoField = ({
  control,
  errors,
  isCepLoading,
  customOnChange,
}) => {
  return (
    <LabelArea text={"Estado"}>
      <FormAutocomplete
        control={control}
        name="estado"
        customOnChange={customOnChange}
        errorMessage={errors.estado?.message}
        disabled={isCepLoading}
        renderOption={(option) => <Typography>{option.label}</Typography>}
        getOptionLabel={(option) => option.label}
        getOptionSelected={(option, value) => option.value === value.value}
        options={states}
      />
    </LabelArea>
  );
};

EstadoField.fieldName = "estado";
EstadoField.schema = yup.object().nullable().required("Selecione o estado");

export const CidadeField = ({ control, errors, estado, isCepLoading }) => {
  const cities = useMemo(() => getCitiesFromState(estado), [estado]);

  return (
    <LabelArea text={"Cidade"}>
      <FormAutocomplete
        control={control}
        name="cidade"
        disabled={isCepLoading}
        errorMessage={errors.cidade?.message}
        options={cities}
      />
    </LabelArea>
  );
};

CidadeField.fieldName = "cidade";
CidadeField.schema = yup.string().nullable().required("Selecione a cidade");

export const RendaField = ({ control, errors }) => {
  return (
    <LabelArea text="Renda Mensal">
      <ControlledCurrencyField
        name="renda"
        control={control}
        errorMessage={errors.renda?.message}
        placeholder="1.100,00"
        min="0"
        max="1000000"
      />
    </LabelArea>
  );
};

RendaField.fieldName = "renda";
RendaField.schema = yup
  .number()
  .nullable()
  .required("Insira a renda mensal")
  .min(0.01, "Valor mínimo de R$0,01")
  .max(30000, "Valor máximo de R$30.000,00")
  .typeError("Não é um número");

export const DataShareCheck = ({ control, errors }) => {
  return (
    <FormCheckbox
      control={control}
      name={DataShareCheck.fieldName}
      label={
        <span>
          Eu permito o envio de mensagens SMS/WhatsApp com instruções para
          finalizar meu cadastro.
        </span>
      }
      errorMessage={errors[DataShareCheck.fieldName]?.message}
    />
  );
};

DataShareCheck.fieldName = "dataShareCheck";
DataShareCheck.schema = yup
  .bool()
  .oneOf([true], "Você precisa aceitar as condições");

export function EmailField({ register, errors }) {
  return (
    <LabelArea text="Email" hint="(opcional)">
      <FormTextField
        inputRef={register}
        errorMessage={errors[EmailField.fieldName]?.message}
        placeholder="nome@email.com"
        name={EmailField.fieldName}
        type="email"
        textTransform="lowercase"
      />
    </LabelArea>
  );
}

EmailField.fieldName = "email";
EmailField.schema = yup
  .string()
  .test("email_test", "Email inválido", (email) => {
    if (email === "") {
      return true;
    } else {
      return validateEmail(email);
    }
  });

export const FeedbackRating = ({ name, control }) => {
  return (
    <ControlledRatingField control={control} name={FeedbackRating.fieldName} />
  );
};

FeedbackRating.fieldName = "rating";
FeedbackRating.schema = yup.string();

// Schemas
export const homeSchema = yup.object().shape({
  [CpfField.fieldName]: CpfField.schema,
});

export const identificacaoSchema = yup.object().shape({
  [NomeField.fieldName]: NomeField.schema,
  [NumCelularField.fieldName]: NumCelularField.schema,
  [InssField.fieldName]: InssField.schema,
  [DataShareCheck.fieldName]: DataShareCheck.schema,
});

export const dadosPessoaisSchema = yup.object().shape({
  [EmailField.fieldName]: EmailField.schema,
  [RendaField.fieldName]: RendaField.schema,
  [DataNascimentoField.fieldName]: DataNascimentoField.schema,
  [EstadoNascimentoField.fieldName]: EstadoNascimentoField.schema,
  [CidadeNascimentoField.fieldName]: CidadeNascimentoField.schema,
  [SexoField.fieldName]: SexoField.schema,
});

export const enderecoSchema = yup.object().shape({
  [CepField.fieldName]: CepField.schema,
  [LogradouroField.fieldName]: LogradouroField.schema,
  [NumeroField.fieldName]: NumeroField.schema,
  [ComplementoField.fieldName]: ComplementoField.schema,
  [BairroField.fieldName]: BairroField.schema,
  [EstadoField.fieldName]: EstadoField.schema,
  [CidadeField.fieldName]: CidadeField.schema,
});

export const feedbackSchema = yup.object().shape({
  [CommentField.fieldName]: CommentField.schema,
  [FeedbackRating.fieldName]: FeedbackRating.schema,
});
