import { makeStyles, Typography } from "@material-ui/core";

import { ReactComponent as EuqueerogranaLogo } from "../images/eu_quero_grana.svg";
import { ReactComponent as MelhoridadeLogo } from "../images/melhor_idade_logo_min.svg";
import { images } from "../utils/constants";

const MobileHomeLeft = ({ step, onButtonClick }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.contentContainer}>
        <div className={classes.freeContainer}>
          <EuqueerogranaLogo className={classes.eqgLogo} />
          <img
            src={images.homeDesktop}
            alt="cartao"
            className={classes.image}
          />
          <div className={classes.logoTextContainer}>
            <MelhoridadeLogo className={classes.melhoridadeLogo} />
            <Typography>Cartão</Typography>
            <Typography>de Crédito</Typography>
            <Typography>Consignado</Typography>
          </div>

          <div className={classes.textContainer}>
            <Typography>A melhor idade tem o privilégio</Typography>
            <Typography>da melhor solução financeira.</Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "60%",
    background:
      "linear-gradient(180deg, #eb6c00, #eb6d00, #ec7100, #ee7701, #ef7f02, #f18605, #f38e0a, #f49610, #f59d15, #f6a219, #f7a61c, #f7a71d)",
  },
  contentContainer: {
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    left: 0,
    top: 0,
    overflow: "hidden",
  },
  freeContainer: {
    position: "absolute",
    width: "100%",
    height: "100%",
    maxWidth: "400px",
  },
  image: {
    width: "42vh",
    maxWidth: "350px",
    position: "absolute",
    right: -35,
    bottom: 85,
    zIndex: "1",
  },
  eqgLogo: {
    position: "absolute",
    left: 36,
    top: 32,
    transform: "scale(1.5)",
    zIndex: "2",
  },
  logoTextContainer: {
    position: "absolute",
    left: 24,
    bottom: 75,
    zIndex: "2",
    "& p": {
      color: "white",
      fontFamily: theme.typography.secondaryFontFamily,
      fontWeight: theme.typography.fontWeightBold,
      fontSize: "22px",
      lineHeight: "27.3px",
    },
  },
  melhoridadeLogo: {
    transform: "scale(1)",
    zIndex: "2",
  },
  textContainer: {
    position: "absolute",
    left: 24,
    bottom: 16,
    zIndex: "2",

    "& p": {
      color: "white",
      fontFamily: theme.typography.secondaryFontFamily,
      fontWeight: theme.typography.fontWeightSemibold,
      fontSize: "16px",
    },
  },
  "@media(min-width: 360px)": {
    image: {
      right: -45,
      width: "45vh",
    },
  },
  "@media(min-width: 375px)": {
    image: {
      right: -45,
      width: "50vh",
      bottom: 100,
    },
  },
  "@media(min-height: 1024px) and (max-width: 959px)": {
    root: {
      height: "45%",
    },
    image: {
      right: -35,
      top: 20,
    },
    logoTextContainer: {
      left: 24,
      top: 260,
    },

    textContainer: {
      left: 24,
      top: 400,
    },
  },
}));

export default MobileHomeLeft;
