import React, { useCallback } from "react";

import clsx from "clsx";

import Autocomplete from "@material-ui/lab/Autocomplete";

import InputMask from "react-input-mask";

import { Controller } from "react-hook-form";
import { floatToBrl } from "@unicabr/front-utils";

import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import { UilExclamationCircle, UilAngleDown } from "@iconscout/react-unicons";

import { ReactComponent as Face1 } from "../images/icons/face_1.svg";
import { ReactComponent as Face1Active } from "../images/icons/face_1_active.svg";
import { ReactComponent as Face2 } from "../images/icons/face_2.svg";
import { ReactComponent as Face2Active } from "../images/icons/face_2_active.svg";
import { ReactComponent as Face3 } from "../images/icons/face_3.svg";
import { ReactComponent as Face3Active } from "../images/icons/face_3_active.svg";
import { ReactComponent as Face4 } from "../images/icons/face_4.svg";
import { ReactComponent as Face4Active } from "../images/icons/face_4_active.svg";
import { ReactComponent as Face5 } from "../images/icons/face_5.svg";
import { ReactComponent as Face5Active } from "../images/icons/face_5_active.svg";

import {
  Button,
  FormLabel,
  TextField,
  InputAdornment,
  makeStyles,
  Typography,
  useTheme,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  Slider,
  FormHelperText,
  CircularProgress,
  Backdrop,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@material-ui/core";

export const FormButtonOutlined = ({ variant, children, ...rest }) => {
  const classes = useStylesFormButton();

  return (
    <Button variant="outlined" className={classes.outlined} {...rest}>
      {children}
    </Button>
  );
};

export const FormButtonContained = ({ variant, children, ...rest }) => {
  const classes = useStylesFormButton();

  return (
    <Button
      variant="contained"
      className={classes.contained}
      color="primary"
      {...rest}
    >
      {children}
    </Button>
  );
};

const useStylesFormButton = makeStyles((theme) => ({
  contained: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "24px",
    fontFamily: "Open Sans, sans-serif",
    color: "white",
    fontWeight: "600",
    width: "100%",
    fontSize: "16px",
    height: "48px",
    boxShadow: theme.shadows[0],
    textTransform: "none",
    "&:disabled": {
      boxShadow: theme.shadows[0],
      backgroundColor: "#D5D5D5",
      color: "#808080",
    },
    "&:hover": {
      boxShadow: theme.shadows[0],
    },
  },
  outlined: {
    borderColor: theme.palette.primary.main,
    borderWidth: "2px",
    borderRadius: "24px",
    fontFamily: "Open Sans, sans-serif",
    fontSize: "16px",
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightSemibold,
    width: "100%",
    height: "48px",
    boxShadow: theme.shadows[0],
    textTransform: "none",
    "&:disabled": {
      color: "#AAAAAA",
      borderWidth: "2px",
    },
  },
}));

export const LabelArea = ({
  text,
  hint,
  width = "100%",
  labelProps,
  smallerHintMobile,
  style,
  children,
  ...rest
}) => {
  return (
    <div style={{ width: width, ...style }} {...rest}>
      <FieldLabel
        text={text}
        hint={hint}
        smallerHintMobile={smallerHintMobile}
        {...labelProps}
      />
      {children}
    </div>
  );
};

export const FieldLabel = ({
  text,
  hint,
  className,
  smallerHintMobile,
  hintProps,
  ...rest
}) => {
  const classes = useStylesFieldLabel();

  return (
    <FormLabel className={clsx(classes.label, className)} {...rest}>
      {text}{" "}
      <span
        {...hintProps}
        className={clsx(classes.hint, hintProps?.className, {
          [classes.smallHint]: smallerHintMobile,
        })}
      >
        {hint}
      </span>
    </FormLabel>
  );
};

const useStylesFieldLabel = makeStyles((theme) => ({
  label: {
    display: "block",
    fontFamily: theme.typography.primaryFontFamily,
    fontWeight: theme.typography.fontWeightSemibold,
    fontSize: "16px",
    marginBottom: "16px",
    color: theme.palette.text.primary,
  },
  hint: {
    fontWeight: theme.typography.fontWeightRegular,
  },

  [theme.breakpoints.down("xs")]: {
    smallHint: {
      fontSize: "15px",
    },
  },
}));

export const FormTextField = ({
  errorMessage = "",
  disabled = false,
  placeholder,
  isNumeric,
  startAdornment,
  type = "text",
  error,
  isLoading,
  style,
  textTransform,
  errorNotTriggeredByMessage,
  height,
  endAdornment,
  ...rest
}) => {
  const classes = useStylesFormTextField(500);

  const hasErrorMessage = errorMessage ? true : false;

  return (
    <TextField
      variant="outlined"
      disabled={disabled}
      error={errorNotTriggeredByMessage ? error : hasErrorMessage || error}
      placeholder={placeholder}
      style={{ minWidth: "100%", ...style }}
      helperText={errorMessage}
      FormHelperTextProps={{ classes: { root: classes.helperText } }}
      inputProps={{
        inputMode: isNumeric ? "numeric" : type,
        pattern: isNumeric ? "[0-9]*" : ".*",
        style: {
          textTransform: textTransform,
        },
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" style={{ color: "#2B2B2B" }}>
            {startAdornment || <></>}
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            {isLoading ? (
              <CircularProgress size={18} className={classes.progress} />
            ) : (
              <></>
            )}
            {(hasErrorMessage && !errorNotTriggeredByMessage) || error ? (
              <UilExclamationCircle color="#DB1C23" />
            ) : (
              <></>
            )}
          </InputAdornment>
        ),
        classes: {
          root: classes.root,
          input: classes.input,
          disabled: classes.disabled,
          error: classes.errored,
        },
        style: { height: height },
      }}
      className={classes.field}
      {...rest}
    />
  );
};

const useStylesFormTextField = makeStyles((theme) => ({
  root: {
    borderRadius: "8px",
    minWidth: "100%",
    height: "48px",

    [`& fieldset`]: {
      borderColor: "#9d9d9d",
    },

    "&.MuiOutlinedInput-root.Mui-disabled": {
      backgroundColor: "#F7F7F7",
    },

    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: `${theme.palette.secondary.main}`,
      boxShadow: `0 0 6px #462A7166`,
    },
  },
  helperText: {
    marginLeft: "4px",
    marginTop: "4px",
    fontSize: "14px",
    color: theme.palette.error.main,
    fontFamily: theme.typography.primaryFontFamily,
  },
  errored: {
    width: "100%",
    backgroundColor: "#FFF8F8",
  },
  input: {
    color: "#2B2B2B",
    fontFamily: "Open Sans, sans-serif",
    fontSize: "16px",
    fontWeight: "400",
    textTransform: "uppercase",

    "&::placeholder": {
      color: "#656565",
      textTransform: "none",
    },
  },
  disabled: {
    color: "#AAA",
  },
  adornment: {
    color: "black",
  },
  progress: (size) => ({
    color: "black",
  }),
}));

export const ControlledMaskedField = ({
  control,
  customOnBlur,
  defaultValue,
  disabled,
  error,
  errorMessage,
  errorNotTriggeredByMessage,
  inputMaskProps,
  inputProps,
  label,
  mask,
  name,
  rules,
  isLoading,
  placeholder,
  isNumeric = false,
  textFieldProps,
  startAdornment,
  type,
  ...rest
}) => {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      rules={rules}
      render={({ onBlur, ref, ...renderProps }) => {
        return (
          <InputMask
            disabled={disabled}
            mask={mask}
            maskChar={""}
            onBlur={(e) => {
              onBlur(e);
              if (customOnBlur) {
                customOnBlur(e);
              }
            }}
            {...renderProps}
            {...inputMaskProps}
          >
            {() => (
              <FormTextField
                disabled={disabled}
                errorMessage={errorMessage}
                isNumeric={isNumeric}
                inputRef={ref}
                placeholder={placeholder}
                startAdornment={startAdornment}
                errorNotTriggeredByMessage={errorNotTriggeredByMessage}
                isLoading={isLoading}
                error={error}
                type={type}
                {...textFieldProps}
              />
            )}
          </InputMask>
        );
      }}
      {...rest}
    />
  );
};

export const ControlledCurrencyField = ({
  control,
  name,
  errorMessage,
  max,
  min,
  placeholder,
  customOnChange,
  customOnBlur,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ ref, value, onChange, onBlur, ...renderProps }) => {
        return (
          <CurrencyField
            errorMessage={errorMessage}
            inputRef={ref}
            value={value}
            maximum={max}
            minimum={min}
            placeholder={placeholder}
            customOnChange={(value) => {
              onChange(value);
            }}
            customOnBlur={(e) => {
              onBlur(e);
            }}
          />
        );
      }}
    />
  );
};

export const CurrencyField = ({
  variant = "outlined",
  name,
  errorMessage,
  maximum,
  minimum,
  setValue,
  customOnBlur,
  customOnChange,
  EndAdornment,
  placeholder,
  value,
  inputRef,
  ...rest
}) => {
  const classes = useStylesFormTextField();
  const hasErrorMessage = errorMessage ? true : false;

  return (
    <CurrencyTextField
      name={name}
      variant={variant}
      maximumValue={maximum}
      minimumValue={minimum}
      value={value}
      inputRef={inputRef}
      placeholder={placeholder}
      onChange={(event, value) => {
        if (customOnChange) customOnChange(value);
      }}
      onBlur={(event, value) => {
        if (customOnBlur) {
          customOnBlur(value);
        }
      }}
      decimalCharacter=","
      digitGroupSeparator="."
      textAlign="left"
      style={{ minWidth: "100%" }}
      error={hasErrorMessage}
      helperText={errorMessage}
      className={classes.field}
      FormHelperTextProps={{ classes: { root: classes.helperText } }}
      overrideMinMaxLimits="invalid"
      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Typography
              style={{
                color: "#2B2B2B",
                fontWeight: "600",
                fontFamily: "Open Sans, sans-serif",
                fontSize: "16px",
              }}
            >
              R$
            </Typography>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            {hasErrorMessage ? <UilExclamationCircle color="#DB1C23" /> : <></>}
          </InputAdornment>
        ),
        classes: {
          root: classes.root,
          input: classes.input,
          disabled: classes.disabled,
          error: classes.errored,
        },
      }}
      {...rest}
    />
  );
};

export const MaskedTextField = React.forwardRef(
  (
    {
      disabled,
      customOnBlur,
      onBlur,
      value,
      mask,
      helperText,
      textFieldProps,
      placeholder,
      isNumeric = false,
      ...rest
    },
    ref
  ) => {
    return (
      <InputMask
        disabled={disabled}
        mask={mask}
        maskChar={""}
        ref={ref}
        onBlur={(e) => {
          if (onBlur) {
            onBlur(e);
          }

          if (customOnBlur) {
            customOnBlur(e);
          }
        }}
        {...rest}
      >
        {() => (
          <FormTextField
            disabled={disabled}
            errorMessage={helperText}
            isNumeric={isNumeric}
            placeholder={placeholder}
            {...textFieldProps}
          />
        )}
      </InputMask>
    );
  }
);

export const ControlledMaskedTextField = ({
  control,
  defaultValue,
  name,
  rules,
  disabled,
  mask,
  customOnBlur,
  inputMaskProps,
  placeholder,
  helperText,
  isNumeric = false,
}) => {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      rules={rules}
      render={({ onBlur, onChange, value, ref, ...renderProps }) => {
        return (
          <MaskedTextField
            disabled={disabled}
            mask={mask}
            helperText={helperText}
            customOnBlur={customOnBlur}
            placeholder={placeholder}
            isNumeric={isNumeric}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            ref={ref}
            {...renderProps}
            {...inputMaskProps}
          />
        );
      }}
    />
  );
};

export const FormTitle = ({ children }) => {
  const classes = useStylesFormTitle();

  return (
    <Typography variant="h3" className={classes.text}>
      {children}
    </Typography>
  );
};

const useStylesFormTitle = makeStyles((theme) => ({
  text: {
    color: "#353535",
    fontSize: "24px",
    fontFamily: theme.typography.primaryFontFamily,
    fontWeight: theme.typography.fontWeightSemibold,
  },
}));

export const FormText = ({ children }) => {
  const classes = useStylesFormText();

  return (
    <Typography variant="body2" className={classes.text}>
      {children}
    </Typography>
  );
};

const useStylesFormText = makeStyles((theme) => ({
  text: {
    color: "#353535",
    fontSize: "16px",
    fontFamily: "Open Sans, sans-serif",
    lineHeight: "24px",
  },
}));

export const FormCheckbox = ({
  control,
  errorMessage,
  name,
  label,
  checked,
  ...rest
}) => {
  const classes = useStylesFormCheckbox();

  const hasError = errorMessage ? true : false;

  return (
    <Box>
      <Controller
        control={control}
        name={name}
        render={({ value, ref, onChange, ...props }) => (
          <div
            className={clsx(classes.root, {
              [classes.rootChecked]: value,
              [classes.rootError]: hasError,
            })}
            style={{
              display: "flex",
              jusifyContent: "flex-start",
              alignItems: "start",
            }}
            onClick={() => {
              onChange(!value);
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  checked={value}
                  classes={{
                    root: clsx(classes.checkbox, {
                      [classes.checkboxError]: hasError,
                    }),
                  }}
                />
              }
              value={value}
              style={{ margin: "0px" }}
              classes={{ label: classes.muiLabel }}
              labelPlacement="end"
              inputRef={ref}
              {...rest}
            />
            <Typography
              className={clsx(classes.label, {
                [classes.labelError]: hasError,
              })}
            >
              {label}
            </Typography>
          </div>
        )}
      />

      {hasError && (
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            marginTop: "8px",
          }}
        >
          <UilExclamationCircle
            color="#DB1C23"
            style={{ marginRight: "8px" }}
          />
          <FormHelperText
            className={classes.errorMessage}
            style={{ marginBottom: "4px" }}
          >
            {errorMessage}
          </FormHelperText>
        </div>
      )}
    </Box>
  );
};

const useStylesFormCheckbox = makeStyles((theme) => ({
  root: {
    border: "1px solid transparent",
    padding: 0,
    paddingBottom: "12px",
    paddingRight: "4px",
    cursor: "pointer",
  },
  rootChecked: {
    border: `1px solid ${theme.palette.secondary.main}`,
    backgroundColor: "#F4F3F7",
    borderRadius: "8px",
    color: theme.palette.primary.main,
  },
  rootError: {
    backgroundColor: "#FFF8F8",
    borderRadius: "8px",
  },
  muiLabel: {
    margin: "0px",
  },
  label: {
    color: theme.palette.text.primary,
    fontSize: "16px",
    fontFamily: "Open Sans, sans-serif",
    lineHeight: "24px",
    paddingTop: "10px",
  },
  labelError: {
    color: theme.palette.error.main,
  },
  checkbox: {
    color: "#808080",
    position: "relative",
    left: 0,
    top: 0,
  },
  checkboxError: {
    color: theme.palette.error.main,
  },
  errorMessage: {
    marginLeft: "4px",
    marginTop: "4px",
    fontSize: "14px",
    color: theme.palette.error.main,
    fontFamily: theme.typography.primaryFontFamily,
  },
}));

export const Separator = ({ spacing, isHorizontal }) => {
  const theme = useTheme();

  return (
    <div
      style={{
        margin: isHorizontal
          ? theme.spacing(0, spacing)
          : theme.spacing(spacing, 0),
      }}
    />
  );
};

export const FormButtonSet = ({
  onClickNext,
  onClickBack,
  nextText = "Continuar",
  type,
}) => {
  return (
    <Box display="flex" justifyContent="space-between">
      <FormButtonOutlined onClick={onClickBack}>Voltar</FormButtonOutlined>

      <Separator spacing={2} isHorizontal />

      <FormButtonContained onClick={onClickNext} type={type || "submit"}>
        {nextText}
      </FormButtonContained>
    </Box>
  );
};

export const FormRadioGroup = ({
  control,
  name,
  defaultValue,
  children,
  helperText,
  customOnChange,
}) => {
  const classes = useStylesRadio();

  return (
    <div>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={(props) => (
          <RadioGroup
            {...props}
            className={classes.radioGroup}
            onChange={(e) => {
              if (customOnChange) customOnChange(e.target.value);
              props.onChange(e);
            }}
          >
            {children}
          </RadioGroup>
        )}
      />
      {helperText && (
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            marginTop: "8px",
          }}
        >
          <UilExclamationCircle
            color="#DB1C23"
            style={{ marginRight: "8px" }}
          />

          <FormHelperText
            className={classes.errorText}
            style={{ marginBottom: "4px" }}
          >
            {helperText}
          </FormHelperText>
        </div>
      )}
    </div>
  );
};

export const FormRadioControl = ({ value, label, checked, error }) => {
  const classes = useStylesRadio();

  return (
    <FormControlLabel
      value={value}
      control={
        <Radio
          classes={{
            root: clsx(classes.radioControl, { [classes.radioError]: error }),
          }}
        />
      }
      label={label}
      classes={{
        root: clsx(classes.rootControl, {
          [classes.rootChecked]: checked,
          [classes.rootError]: error,
        }),
        label: classes.label,
      }}
    />
  );
};

const useStylesRadio = makeStyles((theme) => ({
  label: {
    fontFamily: theme.typography.primaryFontFamily,
    fontSize: "16px",
    paddingLeft: theme.spacing(2),
  },
  rootControl: {
    margin: "0px",
    border: `1px solid transparent`,
    borderRadius: "8px",
    height: "52px",
  },
  rootChecked: {
    border: `1px solid ${theme.palette.secondary.main}`,
    backgroundColor: "#F4F3F7",
    borderRadius: "8px",
  },
  rootError: {
    backgroundColor: "#FFF8F8",
    borderRadius: "8px",
    color: theme.palette.error.main,
  },
  radioControl: {
    color: "#808080",
  },
  radioError: {
    color: theme.palette.error.main,
  },
  radioGroup: {
    "& > :not(:last-child)": {
      marginBottom: theme.spacing(4),
    },
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: "14px",
    fontFamily: theme.typography.primaryFontFamily,
  },
}));

export const FormConfirmationModal = ({
  open,
  setOpen,
  title,
  children,
  onSubmit,
  saqueValue,
}) => {
  const classes = useStylesConfirmationModal();

  return (
    <Dialog
      open={open}
      classes={{ root: classes.root, paper: classes.paper }}
      disableBackdropClick
    >
      <DialogTitle style={{ padding: "0px" }}>
        <Typography className={classes.title}>{title}</Typography>
      </DialogTitle>

      <DialogContent style={{ padding: "0px" }}>
        <Typography className={classes.description}>{children}</Typography>
        <Typography className={classes.saque}>
          {floatToBrl(parseInt(saqueValue))}
        </Typography>
      </DialogContent>

      <div className={classes.actions}>
        <Button
          color="primary"
          className={classes.modalButton}
          onClick={() => {
            setOpen(false);
          }}
        >
          Não
        </Button>
        <Button
          color="primary"
          onClick={onSubmit}
          className={clsx(classes.modalButton, classes.modalButtonYes)}
        >
          Sim
        </Button>
      </div>
    </Dialog>
  );
};

const useStylesConfirmationModal = makeStyles((theme) => ({
  root: {
    padding: "0px",
  },
  paper: {
    maxWidth: "504px",
    width: "90%",

    paddingTop: "24px",
    borderRadius: "12px",
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    borderTop: "1px solid #D5D5D5",
    height: "58px",
  },
  title: {
    fontSize: "20px",
    fontWeight: theme.typography.fontWeightSemibold,
    textAlign: "center",
    fontFamily: theme.typography.primaryFontFamily,
    padding: "0px 24px 0px 24px",
    marginBottom: "8px",
  },
  description: {
    color: "#555555",
    fontSize: "16px",
    textAlign: "center",
    fontFamily: theme.typography.primaryFontFamily,
    padding: "0px 28px 0px 28px",
    marginBottom: "16px",
  },
  saque: {
    color: "#1A9537",
    fontSize: "28px",
    textAlign: "center",
    fontWeight: theme.typography.fontWeightSemibold,
    fontFamily: theme.typography.primaryFontFamily,
    padding: "0px 28px 0px 28px",
    marginBottom: "20px",
  },
  modalButton: {
    width: "50%",
    height: "100%",
    borderRadius: "0px",
    fontFamily: theme.typography.primaryFontFamily,
    textTransform: "none",
    fontSize: "16px",
    fontWeight: theme.typography.fontWeightSemibold,
  },

  modalButtonYes: {
    backgroundColor: theme.palette.primary.main,
    color: "white",

    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

export const FormAutocomplete = ({
  defaultValue,
  disabled,
  name,
  control,
  options,
  autocompleteProps,
  errorMessage,
  renderOption,
  getOptionLabel,
  getOptionSelected,
  customOnChange,
  placeholder = "Selecionar",
}) => {
  const classes = useStylesFormAutocomplete();

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={(props) => (
        <Autocomplete
          {...props}
          classes={{
            paper: classes.paper,
            input: classes.input,
            root: clsx(classes.root, { [classes.rootDisabled]: disabled }),
            noOptions: classes.noOptions,
            inputRoot: clsx(classes.inputRoot, {
              [classes.errorRoot]: errorMessage,
              [classes.inputRootDisabled]: disabled,
            }),
          }}
          renderOption={renderOption}
          getOptionLabel={getOptionLabel}
          getOptionSelected={getOptionSelected}
          disabled={disabled}
          popupIcon={
            <>
              {errorMessage && (
                <UilExclamationCircle style={{ color: "#DB1C23" }} />
              )}
              <UilAngleDown
                className={"dropdown-icon"}
                style={{ color: errorMessage ? "#DB1C23" : "#555555" }}
              />
            </>
          }
          options={options}
          color="secondary"
          noOptionsText="Sem opções"
          onChange={(_, data) => {
            if (customOnChange) customOnChange();
            return props.onChange(data);
          }}
          renderInput={(params) => (
            <FormTextField
              {...params}
              errorMessage={errorMessage}
              placeholder={placeholder}
            />
          )}
          {...autocompleteProps}
        />
      )}
    />
  );
};

const useStylesFormAutocomplete = makeStyles((theme) => ({
  root: {
    padding: "0px",
    width: "100%",
    borderColor: "#808080",
    border: "0px",

    "& .MuiAutocomplete-popupIndicatorOpen": {
      transform: "rotate(0deg)",

      "& .MuiIconButton-label .dropdown-icon": {
        transform: "rotate(180deg)",
      },
    },

    [`& fieldset`]: {
      borderColor: "#9d9d9d",
      borderRadius: "8px",
    },

    "&.MuiOutlinedInput-root .Mui-disabled": {
      backgroundColor: "black",
    },
  },
  rootDisabled: {
    [`& fieldset`]: {
      backgroundColor: "#A7A7A715",
    },
  },
  paper: {
    color: theme.palette.text.primary,
    fontFamily: theme.typography.primaryFontFamily,
    boxShadow: theme.shadows[3],
    border: `1px solid ${theme.palette.secondary.main}`,
  },
  input: {
    padding: "0px",
    height: "11px",
    textTransform: "uppercase",
    fontFamily: theme.typography.primaryFontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    "&::placeholder": {
      color: "#656565",
      textTransform: "none",
    },
  },
  noOptions: {
    color: theme.palette.text.primary,
    fontFamily: theme.typography.primaryFontFamily,
  },
  inputRoot: {
    padding: "0px",
    borderColor: theme.palette.primary.main,
    height: "48px",

    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#000",
      borderRadius: "8px",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.secondary.main,
    },
  },

  errorRoot: {
    backgroundColor: "#FFF8F8",
    borderRadius: "8px",

    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.error.main,
      borderRadius: "8px",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.error.main,
    },
  },
  inputRootDisabled: {
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#9d9d9d",
      borderRadius: "8px",
    },
  },
}));

export const FormSlider = ({
  name,
  control,
  defaultValue,
  max,
  min,
  marks,
  step,
  sliderProps,
}) => {
  const classes = useStylesFormSlider();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={(props) => (
        <Slider
          {...props}
          step={step}
          valueLabelDisplay="off"
          onChange={(_, value) => {
            props.onChange(
              value <= min ? min : value >= max ? max : Math.floor(value)
            );
          }}
          max={max}
          min={min}
          marks={marks}
          classes={{
            root: classes.root,
            thumb: classes.thumb,
            active: classes.active,
            track: classes.track,
            rail: classes.rail,
            markLabel: classes.markLabel,
            mark: classes.mark,
          }}
          {...sliderProps}
        />
      )}
    />
  );
};

const useStylesFormSlider = makeStyles((theme) => ({
  root: {
    color: theme.palette.secondary.main,
    height: 8,

    marginTop: "12px",

    '& .MuiSlider-markLabel[data-index="0"]': {
      transform: "translateX(0%)",
    },
    '& .MuiSlider-markLabel[data-index="1"]': {
      transform: "translateX(-100%)",
    },
  },
  mark: {
    backgroundColor: "transparent",
  },
  markLabel: {
    color: "#808080",
    marginTop: "4px",
    fontFamily: theme.typography.primaryFontFamily,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: `2px solid ${theme.palette.secondary.main}`,
    boxShadow: theme.shadows[1],
    marginTop: -8,
    marginLeft: -12,

    "&:hover": {
      boxShadow: `0px 0px 0px 10px ${theme.palette.secondary.main}22`,
    },
    "&$active": {
      boxShadow: `0px 0px 0px 14px ${theme.palette.secondary.main}22`,
    },
  },
  active: {},
  track: {
    height: 8,
    borderRadius: 100,
  },
  rail: {
    height: 8,
    borderRadius: 100,
  },
}));

export const FormBackdrop = ({ isLoading }) => {
  const classes = useStylesBackdrop();

  return (
    <Backdrop className={classes.root} open={isLoading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

const useStylesBackdrop = makeStyles((theme) => ({
  root: {
    position: "fixed",
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export const ControlledRatingField = ({ name, control, errors }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ value, onChange, onBlur }) => {
        return (
          <RatingField value={value} onChange={onChange} onBlur={onBlur} />
        );
      }}
    />
  );
};

export const RatingField = ({ value, onChange, onBlur }) => {
  const classes = useStylesRatingField();

  const handleClick = useCallback(
    (newValue) => {
      if (newValue === value) {
        if (onChange) onChange(-1);
      } else {
        if (onChange) onChange(newValue);
      }
    },
    [onChange, value]
  );

  return (
    <div
      className={classes.root}
      onBlurCapture={(e) => {
        if (onBlur) onBlur(e);
      }}
    >
      <IconButton
        size="small"
        onClick={handleClick.bind(this, 1)}
        className={clsx(classes.face, { [classes.active]: value === 1 })}
      >
        {value === 1 ? <Face1Active /> : <Face1 />}
      </IconButton>
      <IconButton
        onClick={handleClick.bind(this, 2)}
        className={clsx(classes.face, { [classes.active]: value === 2 })}
      >
        {value === 2 ? <Face2Active /> : <Face2 />}
      </IconButton>
      <IconButton
        size="small"
        onClick={handleClick.bind(this, 3)}
        className={clsx(classes.face, { [classes.active]: value === 3 })}
      >
        {value === 3 ? <Face3Active /> : <Face3 />}
      </IconButton>
      <IconButton
        onClick={handleClick.bind(this, 4)}
        className={clsx(classes.face, { [classes.active]: value === 4 })}
      >
        {value === 4 ? <Face4Active /> : <Face4 />}
      </IconButton>
      <IconButton
        onClick={handleClick.bind(this, 5)}
        className={clsx(classes.face, { [classes.active]: value === 5 })}
      >
        {value === 5 ? <Face5Active /> : <Face5 />}
      </IconButton>
    </div>
  );
};

const useStylesRatingField = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
  },
  face: {
    padding: 0,
    width: "56px",
    height: "56px",
    "& span svg": {
      transform: "scale(0.8)",
      transition: "transform 300ms",
    },
  },
  active: {
    "& span svg": {
      transform: "scale(1)",
      padding: 0,
    },
  },
}));
