import { createMuiTheme } from "@material-ui/core";
import { ptBR } from "@material-ui/core/locale";

const backgroundDefault = "#FFF";

const primary = "#FF7906";
const secondary = "#462A71";

const error = "#DB1C23";

const textPrimary = "#2B2B2B";
const textSecondary = "#F7F7F7";

export default createMuiTheme(
  {
    palette: {
      background: {
        default: backgroundDefault,
      },
      error: {
        main: error,
      },
      primary: {
        main: primary,
      },
      secondary: {
        main: secondary,
      },
      text: {
        primary: textPrimary,
        secondary: textSecondary,
      },
    },
    typography: {
      primaryFontFamily: "Open Sans, sans-serif",
      secondaryFontFamily: "Montserrat, sans-serif",
      fontWeightSemibold: 600,
      fontWeightExtrabold: 800,
    },
    spacing: 4,
    breakpoints: {
      values: {
        xs: 0,
        xss: 320,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
  },
  ptBR
);
