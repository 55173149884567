import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";

import cardReducer from "./card_reducer";

const store = configureStore({
  reducer: { card: cardReducer },
  middleware: (getDefaultMiddleware) =>
    process.env.NODE_ENV !== "production"
      ? getDefaultMiddleware().concat(logger)
      : getDefaultMiddleware(),
});

export default store;
