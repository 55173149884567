import { useCallback } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useSelector, useDispatch } from "react-redux";

import { useHistory } from "react-router-dom";
import { validationMode } from "../utils/constants";
import { useIsMobile, useSnackbar } from "../utils/hooks";

import { floatToBrl } from "@unicabr/front-utils";

import SecureEnv from "../components/SecureEnv";
import FeedbackHeader from "../components/MobileFeedbackHeader";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import DesktopFeedbackHeader from "../components/DesktopFeedbackHeader";
import { FormBackdrop, FormButtonContained } from "../components/RawComponents";

import {
  CommentField,
  feedbackSchema,
  FeedbackRating,
} from "../components/ReadyComponents";

import {
  saveFeedback,
  selectDadosPessoais,
  selectFirstName,
  selectIsLoading,
} from "../store/card_reducer";

const Feedback = () => {
  const isMobile = useIsMobile();
  const classes = useStyles();

  const { showErrorDefaultSnackbar } = useSnackbar();

  const dadosPessoais = useSelector(selectDadosPessoais);

  const firstName = useSelector(selectFirstName);
  const dispatch = useDispatch();

  const history = useHistory();

  const isLoading = useSelector(selectIsLoading);

  const { control, errors, handleSubmit, register } = useForm({
    defaultValues: {
      [CommentField.fieldName]: "",
      [FeedbackRating.fieldName]: -1,
    },
    mode: validationMode,
    resolver: yupResolver(feedbackSchema),
  });

  const onSubmit = useCallback(
    async (formData) => {
      const result = await dispatch(saveFeedback(formData));

      if (saveFeedback.fulfilled.match(result)) {
        history.push("/cartao-inss-sucesso");
      } else {
        showErrorDefaultSnackbar(result);
      }
    },
    [showErrorDefaultSnackbar, history, dispatch]
  );

  return (
    <div className={classes.root}>
      {isMobile ? <FeedbackHeader /> : <DesktopFeedbackHeader />}

      <div className={classes.contentContainer}>
        <Grid
          container
          spacing={4}
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate={true}
          className={classes.form}
        >
          <Grid item xs={12} className={classes.titleContainer}>
            <Typography className={classes.title}>
              Parabéns,{" "}
              <span
                className={classes.pinkText}
                style={{ textTransform: "capitalize" }}
              >
                {firstName}
              </span>
              !
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography className={classes.commonText}>
              Sua solicitação de cartão de crédito consignado foi enviada com
              sucesso!
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <div className={classes.limitContainer}>
              <Typography className={classes.commonText}>
                O valor estimado do seu limite de crédito pré-aprovado é de
              </Typography>
              <Typography className={classes.limitText}>
                {floatToBrl(dadosPessoais?.renda * 0.05 * 32)}
              </Typography>
              <Typography className={classes.smallText}>
                Sujeito a análise de crédito, cadastro e margem consignável
                disponível.
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12}>
            <Typography className={classes.commonText}>
              Em breve um de nossos gerentes virtuais entrará em contato com
              você.
            </Typography>
          </Grid>

          <Grid item xs={12} style={{ marginTop: "16px" }}>
            <Typography className={classes.sectionText}>
              Sua opinião é muito importante para nós!
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography className={classes.commonText}>
              O quão satisfeito você ficou com o nosso atendimento?
            </Typography>
          </Grid>

          <Grid item xs={12} style={{ marginBottom: 14 }}>
            <FeedbackRating control={control} />
          </Grid>

          <Grid item xs={12}>
            <CommentField errors={errors} register={register} />
          </Grid>

          <Grid item xs={12} style={{ marginTop: "24px" }}>
            <FormButtonContained type="submit">Enviar</FormButtonContained>
          </Grid>

          <Grid item xs={12} style={{ marginTop: "8px" }}>
            <Typography
              className={classes.commonText}
              style={{
                color: "#555",
                textAlign: "center",
                marginBottom: "8px",
              }}
            >
              Central de Relacionamento
            </Typography>
            <Typography
              className={classes.commonText}
              style={{ textAlign: "center" }}
            >
              <b>(19) 3325 - 7007</b>
            </Typography>
            <Typography
              className={classes.commonText}
              style={{ textAlign: "center" }}
            >
              <b>falecom@euquerograna.com.br</b>
            </Typography>
          </Grid>

          {isMobile && (
            <Grid item xs={12} style={{ marginTop: "0px" }}>
              <SecureEnv inForm />
            </Grid>
          )}
        </Grid>
      </div>
      <FormBackdrop isLoading={isLoading} />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  contentContainer: {
    padding: "16px",
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    paddingTop: "24px",
  },
  form: {
    maxWidth: "450px",
  },
  title: {
    fontFamily: theme.typography.secondaryFontFamily,
    fontSize: "24px",
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.secondary.main,
  },
  pinkText: {
    color: "#F23A7D",
  },
  commonText: {
    fontFamily: theme.typography.primaryFontFamily,
    fontSize: "16px",
  },
  smallText: {
    fontFamily: theme.typography.primaryFontFamily,
    fontSize: "14px",
  },
  limitText: {
    color: "#1A9537",
    fontFamily: theme.typography.primaryFontFamily,
    fontSize: "24px",
    fontWeight: theme.typography.fontWeightSemibold,
    margin: "4px 0px",
  },
  sectionText: {
    color: theme.palette.secondary.main,
    fontFamily: theme.typography.secondaryFontFamily,
    fontSize: "18px",
    fontWeight: theme.typography.fontWeightBold,
  },
  limitContainer: {
    backgroundColor: "#EBF8EF",
    padding: "8px",
    borderRadius: "8px",
  },
  [theme.breakpoints.up("md")]: {
    root: { flexDirection: "row", overflow: "hidden" },
    contentContainer: {
      paddingTop: "32px",
      overflow: "auto",
    },
  },
}));

export default Feedback;
