import cardService from "../service/card_service";
import { isServerConnected } from "../utils/constants";

import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

import {
  cpfToApi,
  dateToApi,
  nameToApi,
  phoneNumberToApi,
} from "@unicabr/front-utils";

import {
  BairroField,
  CepField,
  CidadeField,
  CidadeNascimentoField,
  CommentField,
  ComplementoField,
  CpfField,
  DataNascimentoField,
  EmailField,
  EstadoField,
  EstadoNascimentoField,
  FeedbackRating,
  InssField,
  LogradouroField,
  NomeField,
  NumCelularField,
  NumeroField,
  RendaField,
  SexoField,
} from "../components/ReadyComponents";

const saveStorage = (state) => {
  sessionStorage.setItem("cardData", JSON.stringify(state));
};

const loadStorage = () => {
  const sessionData = JSON.parse(sessionStorage.getItem("cardData"));

  return {
    id: sessionData?.id || "",
    path: sessionData?.path || "no_card",
    cpf: sessionData?.cpf || "",
    hasCard: sessionData?.hasCard || false,
    codigoConsultor: sessionData?.codigo_consultor || "00000",
    view: sessionData?.view || "default",
    isLoading: false,
    dadosPessoais: sessionData?.dadosPessoais || {},
    identificacao: sessionData?.identificacao || {},
    endereco: sessionData?.endereco || {},
  };
};

export const saveCpf = createAsyncThunk(
  "card/savecpf",
  async (data, { getState, rejectWithValue }) => {
    try {
      if (!isServerConnected) {
        return {
          id: 0,
          form: data,
          hasCard: false,
        };
      }

      const codigo = getState().card.codigoConsultor;

      const body = {
        cpf: cpfToApi(data[CpfField.fieldName]),
        codigo: codigo || "00000",
        robo: null,
      };

      const response = await cardService.createTemp(body);

      return {
        id: response.data.id,
        hasCard: response.data.possuiCard,
        form: data,
      };
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const saveIdentificacao = createAsyncThunk(
  "card/saveidentificacao",
  async (data, { getState, rejectWithValue }) => {
    try {
      if (isServerConnected) {
        const id = getState().card.id;
        const codigo = getState().card.codigoConsultor;

        const isPensionista =
          data[InssField.fieldName] === "true" ? true : false;

        const body = {
          nome: nameToApi(data[NomeField.fieldName]),
          celular: phoneNumberToApi(data[NumCelularField.fieldName]),
          pensionista: isPensionista,
          codigo,
        };

        await cardService.saveTemp(id, body);
      }

      return {
        form: data,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const saveDadosPessoais = createAsyncThunk(
  "card/savedadospessoais",
  async (data, { getState, rejectWithValue }) => {
    try {
      if (isServerConnected) {
        const id = getState().card.id;
        const codigo = getState().card.codigoConsultor;

        const body = {
          cardRetorno: { valorRenda: data[RendaField.fieldName] },
          email: data[EmailField.fieldName],
          sexo: data[SexoField.fieldName],
          dataNascimento: dateToApi(data[DataNascimentoField.fieldName]),
          ufNascimento: data[EstadoNascimentoField.fieldName].value,
          cidadeNascimento: data[CidadeNascimentoField.fieldName],
          codigo,
        };

        await cardService.saveTemp(id, body);
      }

      return {
        form: data,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const saveEndereco = createAsyncThunk(
  "card/saveendereco",
  async (data, { getState, rejectWithValue }) => {
    try {
      if (isServerConnected) {
        const id = getState().card.id;
        const codigo = getState().card.codigoConsultor;

        const body = {
          endereco: {
            bairro: data[BairroField.fieldName],
            cep: data[CepField.fieldName],
            logradouro: data[LogradouroField.fieldName],
            complemento: data[ComplementoField.fieldName],
            numero: data[NumeroField.fieldName],
            uf: data[EstadoField.fieldName].value,
            cidade: data[CidadeField.fieldName],
          },
          codigo,
        };

        await cardService.saveTemp(id, body);
      }

      return {
        form: data,
      };
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const saveFeedback = createAsyncThunk(
  "card/savefeedback",
  async (data, { getState, rejectWithValue }) => {
    try {
      if (isServerConnected) {
        const id = getState().card.id;

        const body = {
          cliente: id,
          nota: data[FeedbackRating.fieldName],
          comentario: data[CommentField.fieldName],
        };

        await cardService.saveFeedback(body);
      }
    } catch (err) {
      throw err;
    }
  }
);

export const cardSlice = createSlice({
  name: "card",
  initialState: loadStorage(),
  reducers: {
    resetStorage: (state) => {
      sessionStorage.clear();
      return {};
    },
    storeCode: (state, { payload }) => {
      state.codigoConsultor = payload;
      saveStorage(state);
    },
    storeId: (state, { payload }) => {
      state.id = payload;
      saveStorage(state);
    },
    storeView: (state, { payload }) => {
      state.view = payload;
      saveStorage(state);
    },
    storePath: (state, { payload }) => {
      state.path = payload;
      saveStorage(state);
    },
    storeSaque: (state, { payload }) => {
      state.saque = payload;
      saveStorage(state);
    },
  },
  extraReducers: {
    [saveCpf.pending]: (state) => {
      state.isLoading = true;
    },
    [saveCpf.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.id = payload?.id;
      state.hasCard = payload?.hasCard;
      state.identificacao = payload?.form;
      saveStorage(state);
    },
    [saveCpf.rejected]: (state) => {
      state.isLoading = false;
    },
    [saveIdentificacao.pending]: (state, { payload }) => {
      state.isLoading = true;
    },
    [saveIdentificacao.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.identificacao = {
        ...state.identificacao,
        ...payload?.form,
        completed: true,
      };
      saveStorage(state);
    },
    [saveIdentificacao.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [saveDadosPessoais.pending]: (state) => {
      state.isLoading = true;
    },
    [saveDadosPessoais.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.dadosPessoais = { ...payload?.form, completed: true };
      saveStorage(state);
    },
    [saveDadosPessoais.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [saveEndereco.pending]: (state, { payload }) => {
      state.isLoading = true;
    },
    [saveEndereco.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.endereco = { ...payload.form, completed: true };
      saveStorage(state);
    },
    [saveEndereco.rejected]: (state) => {
      state.isLoading = false;
    },
    [saveFeedback.pending]: (state) => {
      state.isLoading = true;
    },
    [saveFeedback.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      saveStorage(state);
    },
    [saveFeedback.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export const {
  resetStorage,
  storePath,
  storeSaque,
  storeCode,
  storeView,
  storeId,
} = cardSlice.actions;

export const selectCardData = (state) => state.card;
export const selectIsLoading = (state) => state.card.isLoading || false;
export const selectCodigoConsultor = (state) => state.card.codigoConsultor;

export const selectDadosPessoais = (state) => ({
  ...state.card?.dadosPessoais,
  cpf: state.card?.cpf,
});

export const selectIdentificacao = createSelector(
  selectCardData,
  (card) => card?.identificacao
);

export const selectEndereco = createSelector(
  selectCardData,
  (card) => card?.endereco
);

export const selectCompleted = createSelector(selectCardData, (card) => {
  return {
    home: card?.identificacao?.[CpfField.fieldName] ? true : false,
    identificacao: card?.identificacao?.completed,
    "dados-pessoais": card?.dadosPessoais?.completed,
    endereco: card?.endereco?.completed,
  };
});

export const selectFirstName = createSelector(
  selectIdentificacao,
  (identificacao) => identificacao?.[NomeField.fieldName]?.split(" ")?.[0]
);

export const selectHasCard = createSelector(
  selectCardData,
  (card) => card?.hasCard
);

export const selectView = createSelector(selectCardData, (card) => card?.view);

export const selectPath = createSelector(selectCardData, (card) => card?.path);

export default cardSlice.reducer;
