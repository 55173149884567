import { makeStyles, Typography } from "@material-ui/core";
import { ReactComponent as EuqueerogranaLogo } from "../images/eu_quero_grana.svg";

const Header = ({ step, stepTitle, style }) => {
  const classes = useStyles();

  return (
    <div className={classes.root} style={style}>
      <div className={classes.container}>
        <div>
          <Typography variant="body1" className={classes.stepText}>
            Passo {step} de {3}
          </Typography>
          <Typography vairant="body1" className={classes.title}>
            {stepTitle}
          </Typography>
        </div>

        <EuqueerogranaLogo className={classes.eqgLogo} />
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "start",
    display: "flex",
    background:
      "linear-gradient(90deg, #cf3766, #d13865, #d73b62, #de405d, #e74856, #ee514e, #f55d45, #fa693b, #fc7432, #fe7d29, #fe8422, #fe861f)",
    height: "74px",
    minHeight: "74px",
    justifyContent: "center",
    padding: "0px 24px",
    width: "100%",
  },
  container: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    maxWidth: "454px",
    height: "100%",
    width: "100%",
  },
  title: {
    color: theme.palette.text.secondary,
    fontFamily: theme.typography.primaryFontFamily,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: "20px",
  },
  eqgLogo: {
    transform: "scale(1.2)",
  },
  stepText: {
    color: theme.palette.text.secondary,
    fontFamily: theme.typography.primaryFontFamily,
    fontWeight: theme.typography.fontWeightSemibold,
    fontSize: "14px",
  },
  "@media (max-width: 320px)": {
    title: {
      fontSize: "18px",
    },
  },
}));

export default Header;
