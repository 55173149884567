import { useCallback, useState } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import cep from "cep-promise";
import { validationMode } from "../../utils/constants";
import { ufToState } from "@unicabr/front-utils";

import { useDispatch, useSelector } from "react-redux";
import { saveEndereco, selectEndereco } from "../../store/card_reducer";

import { useIsMobile, useSnackbar } from "../../utils/hooks";

import { FormLayout } from "../../components/Layouts";
import { FormText } from "../../components/RawComponents";
import { Grid, Typography, useTheme } from "@material-ui/core";

import {
  BairroField,
  CepField,
  CidadeField,
  ComplementoField,
  enderecoSchema,
  EstadoField,
  FormFooter,
  LogradouroField,
  NumeroField,
} from "../../components/ReadyComponents";

const Endereco = ({ handleNext, handleBack }) => {
  const dispatch = useDispatch();
  const endereco = useSelector(selectEndereco);

  const { showErrorDefaultSnackbar } = useSnackbar();

  const {
    control,
    errors,
    handleSubmit,
    watch,
    register,
    setValue,
    clearErrors,
    setError,
  } = useForm({
    defaultValues: {
      [CepField.fieldName]: endereco?.cep || "",
      [LogradouroField.fieldName]: endereco?.logradouro || "",
      [NumeroField.fieldName]: endereco?.numero || "",
      [ComplementoField.fieldName]: endereco?.complemento || "",
      [BairroField.fieldName]: endereco?.bairro || "",
      [EstadoField.fieldName]: endereco?.estado || null,
      [CidadeField.fieldName]: endereco?.cidade || null,
    },
    mode: validationMode,
    resolver: yupResolver(enderecoSchema),
  });

  // Cep service
  const [isCepLoading, setIsCepLoading] = useState(false);
  const wAddress = watch(["logradouro", "bairro", "cidade", "estado"]);

  const onSubmit = useCallback(
    async (formData) => {
      const result = await dispatch(saveEndereco(formData));

      if (saveEndereco.fulfilled.match(result)) {
        handleNext();
        window.dataLayer.push({ event: "cartao-consginado-inss-success" });
      } else {
        showErrorDefaultSnackbar(result);
      }
    },
    [dispatch, handleNext, showErrorDefaultSnackbar]
  );

  const handleOnBlurCep = useCallback(
    (e) => {
      if (e?.target?.value?.length === 9) {
        setIsCepLoading(true);
        clearErrors(CepField.fieldName);

        cep(e.target.value)
          .then((address) => {
            setIsCepLoading(false);

            setValue(LogradouroField.fieldName, address.street);
            setValue(BairroField.fieldName, address.neighborhood);
            setValue(CidadeField.fieldName, address.city);
            setValue(EstadoField.fieldName, ufToState(address.state));

            clearErrors([
              LogradouroField.fieldName,
              BairroField.fieldName,
              CidadeField.fieldName,
              EstadoField.fieldName,
            ]);
          })
          .catch((err) => {
            setError("cep", {
              type: "manual",
              message: "Insira um CEP válido",
            });

            setIsCepLoading(false);
          });
      }
    },
    [clearErrors, setError, setValue]
  );
  const theme = useTheme();
  const isMobile = useIsMobile();

  const handleStateChange = useCallback(() => {
    setValue(CidadeField.fieldName, null);
  }, [setValue]);

  return (
    <FormLayout onSubmit={handleSubmit(onSubmit)}>
      {!isMobile && (
        <Grid item xs={12} style={{ marginBottom: "-12px" }}>
          <Typography
            variant="h6"
            style={{
              color: theme.palette.secondary.main,
              fontFamily: theme.typography.primaryFontFamily,
              fontSize: "30px",
              fontWeight: theme.typography.fontWeightBold,
            }}
          >
            Endereço Residencial
          </Typography>
        </Grid>
      )}

      <Grid item xs={12}>
        <FormText>
          Você está quase lá! Para enviarmos o seu cartão, precisamos das
          informações de onde você mora.
        </FormText>
      </Grid>

      <Grid item xs={12}>
        <CepField
          control={control}
          errors={errors}
          isCepLoading={isCepLoading}
          handleOnBlurCep={handleOnBlurCep}
        />
      </Grid>

      <Grid item xs={12}>
        <LogradouroField
          register={register}
          errors={errors}
          isCepLoading={isCepLoading}
        />
      </Grid>

      <Grid item xs={12}>
        <NumeroField control={control} errors={errors} />
      </Grid>

      <Grid item xs={12}>
        <ComplementoField register={register} errors={errors} />
      </Grid>

      <Grid item xs={12}>
        <BairroField
          register={register}
          errors={errors}
          isCepLoading={isCepLoading}
        />
      </Grid>

      <Grid item xs={12}>
        <EstadoField
          control={control}
          errors={errors}
          isCepLoading={isCepLoading}
          customOnChange={handleStateChange}
        />
      </Grid>

      <Grid item xs={12}>
        <CidadeField
          control={control}
          errors={errors}
          estado={wAddress.estado}
          isCepLoading={isCepLoading}
        />
      </Grid>

      <FormFooter onClickBack={handleBack} nextText="Finalizar" />
    </FormLayout>
  );
};

export default Endereco;
