import { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { Redirect } from "react-router";
import { selectCompleted, selectHasCard } from "../store/card_reducer";

import { useLocation } from "react-router-dom";
import { noAccountPath } from "../utils/constants";

const Blocker = () => {
  const location = useLocation();
  const completed = useSelector(selectCompleted);
  const hasCard = useSelector(selectHasCard);

  const [shouldRedirect, setShouldRedirect] = useState(false);

  useEffect(() => {
    const locPathName = location.pathname;
    const step = noAccountPath[locPathName.substring(1)];

    if (step) {
      if (step.num === 1 && !completed.home) {
        setShouldRedirect(true);
      } else if (step.num !== 1) {
        const backStep = Object.values(noAccountPath)[step.num - 2].entity;

        if (!completed[backStep]) {
          setShouldRedirect(true);
        }
      }
    } else if (
      locPathName === "/cartao-inss-oportunidade" &&
      (!completed.identificacao || !hasCard)
    ) {
      setShouldRedirect(true);
    } else if (
      locPathName === "/cartao-inss-outros-produtos" &&
      !completed.identificacao
    ) {
      setShouldRedirect(true);
    }
  }, [location.pathname, completed, hasCard]);

  return (
    <div>
      {process.env.REACT_APP_BLOCKER === "true" && shouldRedirect && (
        <Redirect to="/" />
      )}
    </div>
  );
};

export default Blocker;
