import { useSelector } from "react-redux";
import { useIsMobile } from "../utils/hooks";

import { selectFirstName } from "../store/card_reducer";

import { ReactComponent as Lamp } from "../images/lamp.svg";

import SecureEnv from "../components/SecureEnv";
import { makeStyles, Typography } from "@material-ui/core";
import FeedbackHeader from "../components/MobileFeedbackHeader";
import { FormButtonContained } from "../components/RawComponents";
import DesktopFeedbackHeader from "../components/DesktopFeedbackHeader";

const FeedbackCartao = () => {
  const isMobile = useIsMobile();
  const classes = useStyles();

  const firstName = useSelector(selectFirstName);

  return (
    <div className={classes.root}>
      {isMobile ? <FeedbackHeader /> : <DesktopFeedbackHeader />}

      <div className={classes.contentContainer}>
        <div className={classes.centeredBox}>
          <Lamp className={classes.lamp} />
          <Typography className={classes.title}>
            <span className={classes.pinkText}>{firstName}</span>, temos outra
            oportunidade para você!
          </Typography>

          <Typography className={classes.text} style={{ marginBottom: "6px" }}>
            Olá,{" "}
            <b>
              <span className={classes.pinkText}>{firstName}</span>
            </b>
            ! Identificamos que você já possui este produto.
          </Typography>

          <Typography className={classes.text}>
            Em breve um de nossos gerentes virtuais <b>entrará em contato</b>{" "}
            com outra oportunidade para você!
          </Typography>

          <FormButtonContained
            style={{
              width: "310px",
              marginTop: "40px",
              marginBottom: isMobile ? 24 : 0,
            }}
            onClick={() => {
              window.location.replace("https://euquerograna.com.br/");
            }}
          >
            Finalizar
          </FormButtonContained>
          {isMobile && <SecureEnv inForm />}
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  },
  contentContainer: {
    padding: "16px",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  centeredBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "416px",
    width: "416px",
    textAlign: "center",
  },
  title: {
    fontSize: "24px",
    fontFamily: theme.typography.secondaryFontFamily,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.secondary.main,
    marginBottom: "24px",
  },
  text: {
    fontSize: "16px",
    fontFamily: theme.typography.primaryFontFamily,
    textAlign: "center",
  },
  pinkText: {
    color: "#F23A7D",
    textTransform: "capitalize",
  },
  lamp: {
    marginBottom: "21px",
  },
  "@media(max-height:575px)": {
    contentContainer: {
      alignItems: "start",
    },
  },
  [theme.breakpoints.up("md")]: {
    root: { flexDirection: "row", overflow: "hidden" },
    contentContainer: {
      overflow: "auto",
    },
    lamp: {
      marginBottom: "48px",
    },
    title: {
      fontSize: "30px",
    },
    text: {
      textAlign: "center",
    },
  },
}));

export default FeedbackCartao;
