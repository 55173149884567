import { useIsMobile } from "../utils/hooks";

import { FullscreenLayout } from "../components/Layouts";
import { makeStyles, Typography } from "@material-ui/core";
import FeedbackHeader from "../components/MobileFeedbackHeader";
import DesktopFeedbackHeader from "../components/DesktopFeedbackHeader";
import { images } from "../utils/constants";

const Finish = () => {
  const isMobile = useIsMobile();
  const classes = useStyles();

  return (
    <FullscreenLayout className={classes.root}>
      {isMobile ? <FeedbackHeader /> : <DesktopFeedbackHeader />}

      <div className={classes.contentContainer}>
        <div className={classes.centeredBox}>
          <img src={images.successCheck} alt="check" />
          <Typography className={classes.title}>Sucesso!</Typography>
          <Typography className={classes.text}>
            Agradecemos sua colaboração!
          </Typography>
        </div>
      </div>
    </FullscreenLayout>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: "column",
  },
  contentContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    height: "100%",
  },
  centeredBox: {
    display: "flex",
    justifyContent: "start",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    fontSize: "24px",
    fontFamily: theme.typography.secondaryFontFamily,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.secondary.main,
    marginTop: "12px",
    marginBottom: "8px",
    textAlign: "center",
  },
  text: {
    fontSize: "16px",
    fontFamily: theme.typography.primaryFontFamily,
    textAlign: "center",
  },
  [theme.breakpoints.up("md")]: {
    root: {
      flexDirection: "row",
    },
  },
}));

export default Finish;
