import React from "react";
import ReactDOM from "react-dom";
import { CssBaseline } from "@material-ui/core";

import { ThemeProvider } from "@material-ui/core";
import theme from "./theme/theme";
import App from "./App";
import store from "./store/store";
import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";

import TagManager from "react-gtm-module";

TagManager.initialize({ gtmId: "GTM-MNTN4HT" });

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
          <CssBaseline />
          <App />
        </SnackbarProvider>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
