import { useCallback } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { validationMode } from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router-dom";
import { useIsMobile, useSnackbar } from "../../utils/hooks";

import { FormLayout } from "../../components/Layouts";
import { FormText } from "../../components/RawComponents";
import { Grid, Typography, useTheme } from "@material-ui/core";

import {
  saveIdentificacao,
  selectHasCard,
  selectIdentificacao,
} from "../../store/card_reducer";

import {
  FormFooter,
  InssField,
  NomeField,
  NumCelularField,
  identificacaoSchema,
  DataShareCheck,
} from "../../components/ReadyComponents";

const Identificacao = ({ handleNext, handleBack }) => {
  const dispatch = useDispatch();
  const identificacao = useSelector(selectIdentificacao);
  const hasCard = useSelector(selectHasCard);

  const { showErrorDefaultSnackbar } = useSnackbar();

  const { control, errors, handleSubmit, register } = useForm({
    defaultValues: {
      [NomeField.fieldName]: identificacao?.[NomeField.fieldName] || "",
      [NumCelularField.fieldName]:
        identificacao?.[NumCelularField.fieldName] || "",
      [InssField.fieldName]: identificacao?.[InssField.fieldName] || "",
      [DataShareCheck.fieldName]: true,
    },
    mode: validationMode,
    resolver: yupResolver(identificacaoSchema),
  });

  const isMobile = useIsMobile();
  const theme = useTheme();

  const history = useHistory();

  const onSubmit = useCallback(
    async (formData) => {
      const result = await dispatch(saveIdentificacao(formData));

      if (saveIdentificacao.fulfilled.match(result)) {
        if (hasCard) {
          history.push("/cartao-inss-oportunidade");
        } else {
          if (formData[InssField.fieldName] === "true") {
            handleNext();
          } else {
            history.push("/cartao-inss-outros-produtos");
          }
        }
      } else {
        showErrorDefaultSnackbar(result);
      }
    },
    [dispatch, handleNext, showErrorDefaultSnackbar, hasCard, history]
  );

  return (
    <FormLayout onSubmit={handleSubmit(onSubmit)}>
      {!isMobile && (
        <Grid item xs={12} style={{ marginBottom: "-12px" }}>
          <Typography
            variant="h6"
            style={{
              color: theme.palette.secondary.main,
              fontFamily: theme.typography.primaryFontFamily,
              fontSize: "30px",
              fontWeight: theme.typography.fontWeightBold,
            }}
          >
            Identificação
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <FormText>
          Primeiro, precisamos de algumas informações sobre você. Vamos lá?
        </FormText>
      </Grid>

      <Grid item xs={12}>
        <NomeField register={register} errors={errors} />
      </Grid>

      <Grid item xs={12}>
        <NumCelularField control={control} errors={errors} />
      </Grid>

      <Grid item xs={12}>
        <InssField control={control} errors={errors} />
      </Grid>

      <Grid item xs={12}>
        <DataShareCheck control={control} errors={errors} />
      </Grid>

      <FormFooter onClickBack={handleBack} />
    </FormLayout>
  );
};

export default Identificacao;
