import { makeStyles } from "@material-ui/core";
import { ReactComponent as EuqueerogranaLogo } from "../images/eu_quero_grana.svg";
import { ReactComponent as MelhoridadeLogo } from "../images/melhor_idade_logo.svg";
import { images } from "../utils/constants";
import SecureEnv from "./SecureEnv";

const DesktopFeedbackHeader = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.stepperPanel}>
        <div className={classes.logoContainer}>
          <EuqueerogranaLogo className={classes.logo} />
        </div>
        <div className={classes.imageContainer}>
          <img
            src={images.feedbackDesktop}
            alt="feedback_img"
            className={classes.image}
          />
        </div>
        <div className={classes.melhorIdadeContainer}>
          <MelhoridadeLogo className={classes.melhoridadelogo} />
          <SecureEnv whiteMode />
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "start",
    alignItems: "start",
    height: "100vh",
  },
  stepperPanel: {
    background:
      "linear-gradient(180deg, #eb6c00, #eb6d00, #ec7100, #ee7701, #ef7f02, #f18605, #f38e0a, #f49610, #f59d15, #f6a219, #f7a61c, #f7a71d)",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "center",
    paddingLeft: "130px",
    paddingRight: "64px",
    minWidth: "500px",
    overflow: "auto",
  },
  logoContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "start",
    marginTop: "48px",
  },
  logo: {
    position: "relative",
    left: "-80px",
    transform: "scale(2)",
  },
  imageContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10vh",
  },
  melhorIdadeContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    marginBottom: "32px",
    flex: "1 1 auto",
  },
  melhoridadelogo: {
    marginBottom: "32px",
    transform: "scale(0.65)",
  },
  contentPanel: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
    maxHeight: "1000px",
  },
  image: {
    width: "40vh",
    maxWidth: "400px",
  },
  secureEnv: {
    width: "100%",
    paddingBottom: "32px",
  },
}));

export default DesktopFeedbackHeader;
