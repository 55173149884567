import React from "react";

import clsx from "clsx";

import { Box, makeStyles, Typography } from "@material-ui/core";

import { UilCheck } from "@iconscout/react-unicons";

export function VerticalStepper({ activeStep, children }) {
  return (
    <Box display="flex" flexDirection="column">
      {React.Children.map(children, (child, i) => {
        const childWithProps = React.cloneElement(child, {
          completed: activeStep - 1 > i,
          active: activeStep - 1 === i,
        });
        return (
          <>
            {children.length === i + 1 ? (
              <>{childWithProps}</>
            ) : (
              <>
                {childWithProps}
                <VerticalConnector completed={activeStep - 1 > i} />
              </>
            )}
          </>
        );
      })}
    </Box>
  );
}

export function VerticalStep({ title, subtitle, completed, active, Icon }) {
  const classes = useStylesVerticalStepper();

  return (
    <Box className={classes.item}>
      <Box
        className={clsx(classes.iconContainer, {
          [classes.iconContainerCompleted]: completed,
          [classes.iconContainerActive]: active,
        })}
      >
        {completed ? (
          <UilCheck className={classes.completedIcon} />
        ) : (
          <Icon
            className={clsx(classes.icon, { [classes.iconActive]: active })}
          />
        )}
      </Box>
      <Box className={classes.descriptionContainer}>
        <Typography
          className={clsx(classes.stepNum, {
            [classes.stepNumActive]: active,
          })}
        >
          {subtitle}
        </Typography>
        <Typography
          className={clsx(classes.stepName, {
            [classes.stepNameActive]: active,
          })}
        >
          {title}
        </Typography>
      </Box>
    </Box>
  );
}

export function VerticalConnector({ completed }) {
  const classes = useStylesVerticalStepper();

  return (
    <div
      className={clsx(classes.connector, {
        [classes.connectorCompleted]: completed,
      })}
    ></div>
  );
}

const useStylesVerticalStepper = makeStyles((theme) => ({
  connector: {
    width: "3px",
    height: "32px",
    borderRadius: "30px",
    backgroundColor: "#F9BBB7",
    marginLeft: "22px",
    marginTop: "4px",
    marginBottom: "4px",
    transition: `background-color 100ms ease-in-out 150ms`,
  },
  connectorCompleted: {
    backgroundColor: "white",
  },
  item: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "48px",
    height: "48px",
    borderRadius: "50%",
    border: `3px solid #F9BBB7`,
    transition: `border 200ms, background-color 200ms`,
  },
  iconContainerActive: {
    border: `3px solid white`,
    transition: `border 200ms ease-in-out 250ms`,
  },
  iconContainerCompleted: {
    borderColor: "white",
    backgroundColor: "white",
  },
  icon: {
    color: "#F9BBB7",
    transform: "scale(1.1)",
    transition: `border 200ms, transform 200ms`,
  },
  iconActive: {
    color: "white",
    transition: `color 200ms ease-in-out 200ms`,
  },
  completedIcon: {
    color: "#E74956",
    transform: "scale(1.5)",
  },
  descriptionContainer: {
    paddingLeft: "16px",
  },
  stepNum: {
    color: "#F9BBB7",
    fontFamily: theme.typography.primaryFontFamily,
    fontSize: "14px",
  },
  stepNumActive: {
    color: "white",
  },
  stepName: {
    color: "#F9BBB7",
    fontFamily: theme.typography.primaryFontFamily,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: "16px",
  },
  stepNameActive: {
    color: "white",
  },
}));
