import { BrowserRouter, Route, Switch } from "react-router-dom";
import Blocker from "../components/Blocker";
import Feedback from "../pages/Feedback";
import FeedbackCartao from "../pages/FeedbackCartao";
import FeedbackInss from "../pages/FeedbackInss";
import Finish from "../pages/Finish";

import Home from "../pages/Home";
import StepManager from "../pages/StepManager";
import { views } from "../utils/constants";

const Router = () => {
  return (
    <BrowserRouter>
      <Blocker />

      <Switch>
        <Route exact path="/" component={() => <Home />} />
        <Route
          exact
          path="/cartao-inss-feedback"
          component={() => <Feedback />}
        />
        <Route exact path="/cartao-inss-sucesso" component={() => <Finish />} />
        <Route
          exact
          path="/cartao-inss-oportunidade"
          component={() => <FeedbackCartao />}
        />
        <Route
          exact
          path="/cartao-inss-outros-produtos"
          component={() => <FeedbackInss />}
        />
        <Route
          exact
          path="/consultor/cartao-inss-:codigo"
          component={() => <Home view={views.consultor} />}
        />
        <Route
          exact
          path="/cliente/cartao-inss-:codigo"
          component={() => <Home view={views.cliente} />}
        />
        <Route path="/:step" component={() => <StepManager />} />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
