import { cpfToApi } from "@unicabr/front-utils";
import api from "./api";
class CardService {
  async getTemp(cpf) {
    try {
      const result = await api.get(`/card/clientes-temp/${cpfToApi(cpf)}`);
      return result.data[0];
    } catch (err) {
      throw err;
    }
  }

  async createTemp(info) {
    try {
      const result = await api.post(`/card/clientes-temp/`, {
        ...info,
      });
      return result;
    } catch (err) {
      throw err;
    }
  }

  async saveTemp(id, info) {
    try {
      const result = await api.put(`/card/clientes-temp/${id}`, {
        ...info,
      });
      return result;
    } catch (err) {
      throw err;
    }
  }

  async saveFeedback(info) {
    try {
      const result = await api.post("/card/feedback/", info);
      return result;
    } catch (err) {
      throw err;
    }
  }
}

export default new CardService();
