import { useCallback } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useDispatch, useSelector } from "react-redux";

import { validationMode } from "../../utils/constants";
import { useIsMobile, useSnackbar } from "../../utils/hooks";

import { FormLayout } from "../../components/Layouts";
import { FormText } from "../../components/RawComponents";
import { Grid, Typography, useTheme } from "@material-ui/core";

import {
  saveDadosPessoais,
  selectDadosPessoais,
} from "../../store/card_reducer";

import {
  CidadeNascimentoField,
  DataNascimentoField,
  EmailField,
  EstadoNascimentoField,
  FormFooter,
  dadosPessoaisSchema,
  RendaField,
  SexoField,
} from "../../components/ReadyComponents";

const DadosPessoais = ({ handleNext, handleBack }) => {
  const dispatch = useDispatch();
  const dadosPessoais = useSelector(selectDadosPessoais);

  const { showErrorDefaultSnackbar } = useSnackbar();

  const { control, errors, handleSubmit, register, setValue, watch } = useForm({
    defaultValues: {
      [RendaField.fieldName]: dadosPessoais?.[RendaField.fieldName] || null,
      [EmailField.fieldName]: dadosPessoais?.[EmailField.fieldName] || "",
      [DataNascimentoField.fieldName]:
        dadosPessoais?.[DataNascimentoField.fieldName] || "",
      [EstadoNascimentoField.fieldName]:
        dadosPessoais?.[EstadoNascimentoField.fieldName] || null,
      [CidadeNascimentoField.fieldName]:
        dadosPessoais?.[CidadeNascimentoField.fieldName] || null,
      [SexoField.fieldName]: dadosPessoais?.[SexoField.fieldName] || "",
    },
    mode: validationMode,
    resolver: yupResolver(dadosPessoaisSchema),
  });

  const wEstado = watch(EstadoNascimentoField.fieldName);
  const wSexo = watch(SexoField.fieldName);

  const isMobile = useIsMobile();
  const theme = useTheme();

  const onSubmit = useCallback(
    async (formData) => {
      const result = await dispatch(saveDadosPessoais(formData));

      if (saveDadosPessoais.fulfilled.match(result)) {
        handleNext();
      } else {
        showErrorDefaultSnackbar(result);
      }
    },
    [dispatch, handleNext, showErrorDefaultSnackbar]
  );

  const handleChangeEstadoNascimento = useCallback(() => {
    setValue(CidadeNascimentoField.fieldName, null);
  }, [setValue]);

  return (
    <FormLayout onSubmit={handleSubmit(onSubmit)}>
      {!isMobile && (
        <Grid item xs={12} style={{ marginBottom: "-12px" }}>
          <Typography
            variant="h6"
            style={{
              color: theme.palette.secondary.main,
              fontFamily: theme.typography.primaryFontFamily,
              fontSize: "30px",
              fontWeight: theme.typography.fontWeightBold,
            }}
          >
            Dados Pessoais
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <FormText>
          Para sua segurança e para confirmarmos sua identidade, precisamos de
          mais alguns dados.
        </FormText>
      </Grid>

      <Grid item xs={12}>
        <RendaField control={control} errors={errors} />
      </Grid>

      <Grid item xs={12}>
        <EmailField register={register} errors={errors} />
      </Grid>

      <Grid item xs={12}>
        <DataNascimentoField control={control} errors={errors} />
      </Grid>

      <Grid item xs={12}>
        <EstadoNascimentoField
          control={control}
          errors={errors}
          customOnChange={handleChangeEstadoNascimento}
        />
      </Grid>

      <Grid item xs={12}>
        <CidadeNascimentoField
          control={control}
          errors={errors}
          estado={wEstado}
        />
      </Grid>

      <Grid item xs={12}>
        <SexoField control={control} errors={errors} selectedSexo={wSexo} />
      </Grid>

      <FormFooter onClickBack={handleBack} />
    </FormLayout>
  );
};

export default DadosPessoais;
